var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dialog_sidebar_notice_page",
      on: {
        click: function ($event) {
          return _vm.closeSidebar()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "close_btn cp",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.closeSidebar()
            },
          },
        },
        [_c("div", { staticClass: "close" })]
      ),
      _c(
        "div",
        {
          staticClass: "sidebar_notice_wrapper",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doNothing($event)
            },
          },
        },
        [
          0
            ? _c("div", { staticClass: "sticky_bar_group" }, [
                _c("div", { staticClass: "switch_delete_multiple" }, [
                  _vm._v("批量删除"),
                ]),
              ])
            : _vm._e(),
          _vm.news_list && _vm.news_list.length
            ? _c(
                "div",
                { staticClass: "sidebar_list_group" },
                _vm._l(_vm.news_list, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "sidebar_item_group" },
                    [
                      _c("div", { staticClass: "sidebar_item" }, [
                        !item.isread
                          ? _c("div", { staticClass: "dot" })
                          : _vm._e(),
                        _c("div", { staticClass: "info_group" }, [
                          _c(
                            "div",
                            { staticClass: "avatar_group" },
                            [
                              _c("avatar-detail", {
                                attrs: { msg_data: _vm.getAvatarData(item) },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "details_group" }, [
                            _c("div", { staticClass: "type_group" }, [
                              _c("div", { staticClass: "type_name" }, [
                                _vm._v(_vm._s(item.userName)),
                              ]),
                              _c("div", { staticClass: "time" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("universalDate")(item.updateTime)
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "details_box" }, [
                              _vm._v(_vm._s(_vm.transformDetails(item))),
                            ]),
                            item.type == 4
                              ? _c("div", { staticClass: "task_group" }, [
                                  _vm._v(
                                    "任务: " +
                                      _vm._s(
                                        item.remind && item.remind.taskName
                                      )
                                  ),
                                ])
                              : _vm._e(),
                            item.type == 4
                              ? _c("div", { staticClass: "widget_group" }, [
                                  _vm._v(
                                    "控件: " +
                                      _vm._s(
                                        item.remind && item.remind.conName != ""
                                          ? item.remind.conName
                                          : "未命名控件"
                                      )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", { staticClass: "fun_group" }, [
                          item.type == 4
                            ? _c(
                                "button",
                                {
                                  staticClass: "button btn blue",
                                  on: {
                                    click: function ($event) {
                                      return _vm.leaveFor(item)
                                    },
                                  },
                                },
                                [_vm._v("前往查看")]
                              )
                            : _vm._e(),
                          0
                            ? _c("button", { staticClass: "button btn" }, [
                                _vm._v("查看详情"),
                              ])
                            : _vm._e(),
                          0
                            ? _c("button", { staticClass: "button btn" }, [
                                _vm._v("回复"),
                              ])
                            : _vm._e(),
                          0
                            ? _c("button", { staticClass: "button btn" }, [
                                _vm._v("拒绝"),
                              ])
                            : _vm._e(),
                          0
                            ? _c(
                                "button",
                                {
                                  staticClass: "button btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteNews(item)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _c("div", { staticClass: "no_news" }, [_vm._v("没有通知")]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }