import TaskInfo from '@/models/task/task_info'
import TaskDetail from '@/models/task/task_detail'
import TaskUser from '@/models/task/task_user'
import { listConvertToModel } from '@/models/task/index'
class Task {
  taskInfo
  taskDetails = []
  taskUsers = []
  constructor (params) {
    const {
      taskInfo,
      taskDetails,
      taskUsers,
    } = params ?? {}
    this.taskInfo = new TaskInfo(taskInfo)
    if (taskDetails) {
      this.taskDetails = listConvertToModel({
        list: taskDetails,
        model: TaskDetail
      })
    }
    if (taskUsers) {
      this.taskUsers = listConvertToModel({
        list: taskUsers,
        model: TaskUser
      })
    }
  }
}

export default Task
