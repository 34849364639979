<!--
 * @Author: hugo 1634765756@qq.com
 * @Date: 2022-05-12 14:34:34
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-24 16:35:06
 * @FilePath: /miaohang/src/components/dialog/OperatingSpace/ControlStrip.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div
    class="components_dialog_operating_space_control_strip_page bbox d-flex align-center"
  >
    <div
      class="fun template"
      :class="{on: dialog_fun_status=='template'}"
      @click.stop="changeDialogFunStatus('template')"
    ></div>
    <div
      class="fun text"
      :class="{on: dialog_fun_status=='text'}"
      @click.stop="changeDialogFunStatus('text')"
    ></div>

    <el-upload
      class="fun"
      action="https://jsonplaceholder.typicode.com/posts/"
      multiple
      :show-file-list="false"
      v-if="0"
    >
      <div slot="trigger">
        <div class="fun file" :class="{on: dialog_fun_status=='file'}" @click="dialog_fun_status='file'"></div>
      </div>
    </el-upload>

    <div
      v-if="0"
      class="fun file"
      @click.stop="touchImage"
    ></div>
    <input
      type="file"
      accept="image/*"
      ref="image"
      style="display: none"
      @change="fileChange"
      multiple
    >

    <div
      class="fun record"
      :class="{on: dialog_fun_status=='record'}"
      @click.stop="$openCommunicationRecord()"
      v-if="1"
    ></div>
    <record-of-communication
      v-if="switch_record && 0"
      @handleClose="closeRecord"
    ></record-of-communication>
  </div>
</template>

<script>
import { FileData } from '@/classes'
import RecordOfCommunication from '@/components/PopUp/RecordOfCommunication/index.vue';
export default {
  components: {
    RecordOfCommunication,
  },
  props: {
    dialog: {
      type: Object,
      default: undefined
    }
  },
  data(){
    return {
      switch_record: false,
    }
  },
  computed:{
    dialog_fun_status(){
      return this.$store.state.dialog_fun_status;
    },
  },
  methods: {
    fileChange() {
      // this.$notice({ desc:'正在上传，请不要关闭' })
      console.log(this.$refs.image.files)
      console.log(this.dialog)
      if (this.$refs.image.files.length) {
        this.dialog.files = []
        // 不能直接酱files传递过去 因为files是只读的 无法进行删除等操作
        for (let i = 0; i < this.$refs.image.files.length; i++) {
          this.dialog.files.push(
            new FileData({
              file: this.$refs.image.files[i]
            })
          )
        }
        // this.dialog.files = this.$refs.image.files
        // 打开发送文件弹窗
        this.$openDialogSendFiles({
          dialog: this.dialog
        })
        this.$refs.image.value = "" // 清空图片列
      }
    },
    touchImage() {
      this.$refs.image.click()
    },
    changeDialogFunStatus(type){
      // this.$store.commit('set_dialog_fun_status', type);
      this.$emit('handleChange', type);
    },
    closeRecord(){
      this.switch_record = false;
    }
  },
  
}
</script>

<style lang="scss" scoped>
@mixin fun_img($imgname) {
  &.#{$imgname} {
    background-image: url(~@/assets/images/dialog/#{$imgname}.png);
    &.on {
      background-image: url(~@/assets/images/dialog/#{$imgname}_on.png);
    }
  }
}
.components_dialog_operating_space_control_strip_page{
    width: 100%;
    padding: 20px 30px;
    border-bottom: 2px solid $border_color;
    .fun {
      @include transition;
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin-right: 40px;
      flex-shrink: 0;
      background-size: 100%;
      &:last-child {
        margin-right: 0;
      }
      @include fun_img(template);
      @include fun_img(text);
      @include fun_img(file);
      @include fun_img(record);
      @include fun_img(image);
      &.image:hover{
        background-image: url(~@/assets/images/dialog/image_on.png);
      }
    }
}
</style>