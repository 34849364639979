import store from '@/store'
import { listForClasses, PTaskLog } from '@/types'
import global from '@/api/global'
import vue from '@/main'

class MessageListApi {

  /**
   * 移除任务简述
   * @param params
   * @param {Number} params.taskId
   */
  removeTask = (params) => {
    try {
      const { taskId } = params
      const { message_list } = store.state
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const taskLogIndex = message_list.findIndex((unit) => {
          return Number(unit.taskId) === Number(taskId)
        })
        if (taskLogIndex !== -1) {
          message_list.splice(taskLogIndex, 1)
        } else {
          break
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * 清空message_list
   */
  clearMessageList = () => {
    store.commit('set_message_list', [])
  }

  /**
   * 获取消息列表
   * @param params
   * @param {Number} params.chatId
   * @param {Number} params.chatType
   */
  getMessageList = async (params) => {
    try {
      const { chatId, chatType } = params
      const res = await global.doPost({
        url: '/projectTask/messageList',
        data: { chatId, chatType },
        loading: true
      })
      const { message, contents } = res
      const { tasklogList } = contents
      if (message === 'success') {
        let message_list = []
        if (tasklogList) {
          /**反转之后赋值 */
          message_list = tasklogList.reverse()
          // 将图片【logType === 6】的按照id来排序
          let picType = {index: undefined, pic: undefined}
          for (let i in message_list) {
            if (message_list[i].logType === 6) {
              if (picType.pic === undefined) {
                picType = {index: i, pic: message_list[i]}
              } else {
                // 将id大的往后移
                if (picType.pic.id > message_list[i].id) {
                  message_list[picType.index] = message_list[i]
                  message_list[i] = picType.pic
                } else {
                  picType = {index: i, pic: message_list[i]}
                }
              }
            }
          }
        }
        store.commit(
          'set_message_list',
          listForClasses({
            list:message_list,
            classes: PTaskLog
          })
        )
      }
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * 滚动到底部
   */
  scrollToBottom = () => {
    store.commit(
      'set_task_trigger_scroll_to_bottom',
      true
    )
  }
}

const messageListApi = new MessageListApi()

export default messageListApi