<template>
  <div class="dialog_fun_chat_page bbox d-flex">
    <div class="input_group">
      <div class="appoint_task_group d-flex align-center"
        v-show="is_appoint_task_group"
      >
        <div class="text">回复任务:&nbsp;</div>
        <div class="task_name">{{task_name}}</div>
        <div class="close cp"
          @click="closeAppointTask();"
        ></div>
      </div>
      <el-input
        class="editor"
        type="textarea"
        :rows="3"
        :placeholder="editor_placeholder"
        ref="editor"
        resize="none"
        v-model="editor_content"
        @blur="handleBlur($event)"
        @keydown.native="handleKeydown($event)"
        @input.native="handleKeyup($event)"
        @keydown.enter.native="doEnter($event);"
        @keydown.ctrl.enter.native="doEnterControl($event)"
      >
      </el-input>
      <div class="send_group flex-shrink-0">
        <button class="send button"
          @click="doSend();"
        >发布</button>
        
        <el-popover
          placement="top-end"
          trigger="click"
          v-model="switch_send_options"  
        >
          <div class="send_option_group">
            <div class="options"
              :class="{on: !send_type}"
              @click="send_type=0;switch_send_options=false;"
            >
              <div class="content">按Enter发布讨论内容</div>
              <div class="selected"></div>
            </div>
            <div class="options"
              :class="{on: send_type}"
              @click="send_type=1;switch_send_options=false;"
            >
              <div class="content">按Ctrl+Enter发布讨论内容</div>
              <div class="selected"></div>
            </div>
          </div>
          <div
            slot="reference"
            class="option_btn"></div>
        </el-popover>
      </div>
    </div>
    <at-pop-up
      v-if="switch_at"
      :chat_type="Number($tools.getChatType())"
      :chat_id="Number($global.decodeBase64($route.params.id))"
      :task_id="task_id"
      @handleClose="closeAt"
      @handleSure="addAt"
      @handleAll="atAll"
      :title="task_id?'从任务参与人中选择@对象':'从工作组中选择@对象'"
    ></at-pop-up>
  </div>
</template>
<script>
import tools from '@/api/tools'
import global from '@/api/global'
import AtPopUp from '@/components/PopUp/At/index.vue';
import router from '@/router'
import store from '@/store'
import messageListApi from '@/api/modules/messageList'
import myTaskListApi from '@/api/modules/myTaskList'
export default {
  components:{
    AtPopUp
  },
  data(){
    return {
      editor_content     : '',
      send_type          : 0,       // 发送方式 0/1 enter/control-enter;
      switch_send_options: false,
      switch_at          : false,
      at_cursor_index    : null,
    }
  },
  computed:{
    // ******** Store ***********
    dialog_fun_chat_data() {
      return this.$store.state.dialog_fun_chat_data
    },
    dialog_fun_status(){
      return this.$store.state.dialog_fun_status;
    },
    dialog_click_at_name(){
      return this.$store.state.dialog_click_at_name;
    },
    // ******** Status ***********
    is_appoint_task_group(){
      const { dialog_fun_chat_data } = this
      if (dialog_fun_chat_data !== null) {
        return true;
      }
      return false;
    },
    // ******** Rely On ***********
    task_name(){
      const {dialog_fun_chat_data} = this;
      if(dialog_fun_chat_data === null){
        return '';
      }
      return dialog_fun_chat_data.taskName;
    },
    task_id(){
      const {dialog_fun_chat_data} = this;
      if(dialog_fun_chat_data === null){
        return null;
      }
      const {taskId} = dialog_fun_chat_data;
      return Number(taskId);
    },
    // ******** Other ***********
    editor_placeholder(){
      let status = '输入讨论内容，按Enter快速发布';
      const {send_type} = this;
      if(send_type){
        status = '输入讨论内容，按Ctrl+Enter快速发布';
      }else{
        status = '输入讨论内容，按Enter快速发布';
      }
      return status;
    },
  },
  watch:{
    dialog_fun_status: function(val){
      if(val === 'text'){
        this.$refs.editor.focus();
      }
    },
    '$route.path': function(val){
      this.editor_content = ''; // 清空输入内容
      this.closeAppointTask();
    },
    dialog_click_at_name: function(val){
      if(val !== null){
        this.clickAtUser();
      }
    }
  },
  updated(){
    this.$refs.editor.focus();
  },
  mounted(){
    this.$nextTick(function () {
      if (this.$refs.editor) {
        this.$refs.editor.focus()
      }
      this.closeAppointTask();
      // // console.log(this.$refs.editor.$el.firstChild);
      if (this.$refs.editor.$el) {
        this.$refs.editor.$el.firstChild.spellcheck = false;
      }
    })
  },
  methods: {
    /**
     * @description: 取消回复指定任务
     * @param {*}
     * @return {*}
     */    
    closeAppointTask(){
      this.$store.commit('set_dialog_fun_chat_data', null);
    },
    chineseHandler(e){
      let status = false;
      if(e.keyCode == 229){
        status = true;
      }
      return status;
    },
    /**
     * @description: Enter发送
     * @param {*} e
     * @return {*}
     * 
     * 如果是中文状态 回车不发送 do nothing
     */
    async doEnter(e){
      // // console.log(e);
      // 中文输入法回车
      const is_chinese = this.chineseHandler(e);
      if(is_chinese){
        return false;
      }
      const {send_type} = this;
      // enter发送 && 没按ctrl && 没按shift
      if(!send_type && !e.ctrlKey && !e.shiftKey){
        e.preventDefault();
        this.doSend();
      }
    },
    /**
     * @description: Ctrl+Enter发送
     * @param {*} e
     * @return {*}
     */
    async doEnterControl(e){
      const {send_type} = this;
      if(send_type){
        e.preventDefault();
        this.doSend();
      }else{
        this.editor_content += '\n';
      }
    },
    async doSend(){
        const user = this.$tools.getCookie('user');
        // this.$global.preventDuplicateClicks(async () => {
        // // console.log(this.editor_content);
        const {dialog_fun_chat_data, editor_content} = this;
        if(this.editor_content === ''){
          return;
        }
        let data = {
          chat_type: this.$tools.getChatType(),
          chat_id: this.$global.decodeBase64(this.$route.params.id),
          content: editor_content,
          taskId: '',
        }
        // 如果是单聊 chat_id传自己的id 接收方收到的就是对方id
        // if(Number(data.chat_type) === 0){
        //   data.chat_id = this.$tools.getUserId();
        // }
        if(dialog_fun_chat_data !== null){
          data.taskId = dialog_fun_chat_data.taskId;
        }
        const res = await this.$api.chat.sendChat(data)
        this.editor_content = ''
        if (res && res.message === 'success') {
          const { contents } = res
          if (Number(data.chat_type) === 1) {
            if (Number(contents.PTaskLog.chatId) !== Number(data.chat_id)) {
              // 清除message_list
              messageListApi.removeTask({
                taskId: contents.PTaskLog.taskId
              })
              // 清除my_task_list
              myTaskListApi.removeTask({
                taskId: contents.PTaskLog.taskId
              })
              // 清除回复
              this.closeAppointTask()
              this.$openConfirm({
                title: '该任务已迁移至其他工作组',
                desc: '是否前往工作组查看？',
                onSure: () => {
                  // 跳转到目标工作组
                  router.push({
                    name: 'fun_task_dialog_group',
                    params: {
                      id: tools.btoa(contents.PTaskLog.chatId)
                    }
                  })
                  // 更新消息盒子
                  store.dispatch('get_message_box_list')
                },
                onCancel: async () => {
                  // 如果message_list的长度小于20 则重新获取message_list
                  // 清空
                  messageListApi.clearMessageList()
                  // 获取
                  await messageListApi.getMessageList({
                    chatId: data.chat_id,
                    chatType: data.chat_type
                  })
                  // 滚动到底部
                  this.$nextTick(function () {
                    messageListApi.scrollToBottom()
                  })
                }
              })
              return
            }
          }
          this.$api.chat.pushChatAtMessageList(
            Object.assign(
              {},
              res.contents.PTaskLog
            )
          )
          this.$api.chat.pushChatAtMessageBoxList({
            chatId: data.chat_id,
            chatType: data.chat_type,
            lastSendTime: res.contents.PTaskLog.createTime,
            messageContent: `[${data.content}]`,
            updateusername: user.userName,
            updateUserId: user.userId
          })
          console.log({
            chatId: data.chat_id,
            chatType: data.chat_type,
            lastSendTime: res.contents.PTaskLog.createTime,
            messageContent: `[${data.content}]`,
            updateusername: user.userName,
            updateUserId: user.userId
          })
        }
    },
    handleKeyup(e){
      // console.log(e)
      const chat_type = Number(this.$tools.getChatType());
      if(chat_type === 0){
        return;
      }
      const {data} = e;
      if(!data || !data.length){
        return;
      }
      const at = data[data.length - 1] === '@';
      // const at = this.$api.input.atListener(e);
      // const is_del = this.$api.input.deleteListener(e);
      const cursor_index = this.$refs.editor.$el.firstChild.selectionStart;
      if(at){
        // this.switch_at = true;
        this.$openAt({
          title: this.task_id ? '从任务参与人中选择@对象' : '从工作组中选择@对象',
          chat_type: Number(tools.getChatType()),
          chat_id: Number(global.decodeBase64(this.$route.params.id)),
          task_id: this.task_id,
          handleSure: this.addAt,
          handleAll: this.atAll
        })
        this.$nextTick(function(){
          setTimeout(()=>{
            this.$refs.editor.blur();
            // this.$refs.editor.blur();
            // 获取光标索引
            if(cursor_index){
              this.at_cursor_index = cursor_index;
            }
            // 中文@blur两次
            setTimeout(()=>{
              this.$refs.editor.blur();
            })
          }, 50);
        })
      }


     
      // console. log(this.$refs.editor.$el.firstChild.selectionStart);
   
      // if(is_del){
      //   const str = this.editor_content;
      //   // console.log(str);
      //   const last_char = this.editor_content.substring(cursor_index-1, cursor_index);
      //   // console.log({last_char});
      //   if(last_char === ' '){
      //     const new_str = this.deleteAt({str: this.editor_content, cursor_index});
      //     this.$nextTick(function(){
      //       if(new_str){
      //         this.editor_content = new_str;
      //       }
      //     })
      //   }
      // }
    },
    handleKeydown(e){
     
      const is_del = this.$api.input.deleteListener(e);
      const cursor_index = this.$refs.editor.$el.firstChild.selectionStart;
   
      if(is_del){
        const str = this.editor_content;
        const last_char = this.editor_content.substring(cursor_index-1, cursor_index);
        if(last_char === ' '){
          const res = this.deleteAt({str: this.editor_content, cursor_index});
          this.$nextTick(function(){
            if(res){
              setTimeout(()=>{
                const {new_str, new_cursor_index} = res;
                this.editor_content = new_str;
                this.$nextTick(function(){
                  // 设置新光标位置
                  this.$refs.editor.$el.firstChild.selectionStart = new_cursor_index;
                  this.$refs.editor.$el.firstChild.selectionEnd = new_cursor_index;
                })
              },50)
            }
          })
        }
      }
    },
    atAll(){
      const atStr = this.$api.at.atAll();
      const {at_cursor_index, editor_content} = this;
      const start_str = editor_content.substring(0, at_cursor_index);
      const end_str = editor_content.substring(at_cursor_index);
      const new_str = `${start_str}${atStr}${end_str}`;
      const new_cursor_index = `${start_str}${atStr}`.length;
      this.closeAt();
      this.$nextTick(function(){
        this.editor_content = new_str;
        setTimeout(()=>{
          // 设置新光标位置
          this.$refs.editor.$el.firstChild.selectionStart = new_cursor_index;
          this.$refs.editor.$el.firstChild.selectionEnd = new_cursor_index;
        },50)
      })
    },
    closeAt(){
      this.switch_at = false
      this.$closeAt()
    },
    deleteAt(params={}){
      const {str, cursor_index} = params;
      // const start_str = str.substring(0, cursor_index);
      // const end_str = str.substring(cursor_index);
      const has_at = str.lastIndexOf('@', cursor_index);
      if(has_at !== -1){
        const new_str = str.substring(0, has_at) + str.substring(cursor_index);
        const new_cursor_index = has_at;
        return {new_str, new_cursor_index};
      }
      return false;

    },
    addAt(item){
      const atStr = this.$api.at.atStr(item.atName());
      const {at_cursor_index, editor_content} = this;
      // if(at_cursor_index !== null){
      //   if(at_cursor_index != editor_content.length){

      //   }
      // }
      const start_str = editor_content.substring(0, at_cursor_index);
      const end_str = editor_content.substring(at_cursor_index);
      const new_str = `${start_str}${atStr}${end_str}`;
      const new_cursor_index = `${start_str}${atStr}`.length;
      this.closeAt()
      this.$nextTick(function(){
        this.editor_content = new_str;
        setTimeout(() => {
          // 设置新光标位置
          this.$refs.editor.$el.firstChild.selectionStart = new_cursor_index;
          this.$refs.editor.$el.firstChild.selectionEnd = new_cursor_index;
        },50)
      })
      
    },
    handleBlur(e){
      // console.log(e);
      // // console.log(this.$refs.editor.$el.firstChild.selectionStart);
      const dialog_chat_cursor_index = this.$refs.editor.$el.firstChild.selectionStart;
      // console.log(dialog_chat_cursor_index);
      this.at_cursor_index = dialog_chat_cursor_index;
      this.$store.commit('set_dialog_chat_cursor_index', dialog_chat_cursor_index)
    },
    clickAtUser(){
      const {editor_content, at_cursor_index} = this;
      const {state, commit } = this.$store;
      const {dialog_click_at_name} = state;
      if(dialog_click_at_name !== null){
        let cursor_index = at_cursor_index;
        if(cursor_index > editor_content.length){
          cursor_index = editor_content.length;
        }
        if(at_cursor_index === null){
          cursor_index = editor_content.length;
        }
        const start_str = editor_content.substring(0, cursor_index);
        const end_str = editor_content.substring(cursor_index);
        const new_str = `${start_str}${dialog_click_at_name}${end_str}`;
        const new_cursor_index = `${start_str}${dialog_click_at_name}`.length;
        this.$nextTick(function(){
          this.editor_content = new_str;
          commit('set_dialog_click_at_name', null);
          setTimeout(()=>{
            // 设置新光标位置
            this.$refs.editor.$el.firstChild.selectionStart = new_cursor_index;
            this.$refs.editor.$el.firstChild.selectionEnd = new_cursor_index;
          },50)

        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.dialog_fun_chat_page{
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  .input_group{
    @include bbox;
    @include flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    height: 100%;
    // border-radius: 0px 4px 4px 4px;
    border-radius: 4px;
    // position: absolute;
    overflow: hidden;
    // padding: 4px 0;
    .editor{
      width: 100%;
      flex: 1;
      height: 100%;
      // height: 70px;
      background-color: transparent;
      font-size: 14px;
      overflow: hidden;
    }
    .send_group{
      @include flex;
      justify-content: flex-end;
      width: 100%;
      .send{
        font-size: 14px;
        color: $main_blue;
        cursor: pointer;
        width: 70px;
        height: 32px;
        color: #fff;
        background: #0054A7;
        border-radius: 4px 0 0 4px;
      }
      .option_btn{
        @include background(8px, 5px);
        cursor: pointer;
        width: 30px;
        height: 32px;
        border-radius:  0 4px 4px 0;
        background: {
          color: #004385;
          image: url(~@/assets/images/dialog/fun/chat/send_option.png);
        }
      }
    }
  }
  .appoint_task_group{
    width: 100%;
    font-size: 12px;
    color:#555;
    line-height: 20px;
    padding-bottom: 10px;
    .task_name{
      color: $main_blue;
    }
    .close{
      @include background(16px, 16px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/fun/chat/close.png);
    }
  }
}
.send_option_group{
  .options{
    @include transition;
    @include flex;
    cursor: pointer;
    &.on{
      .content{
        color: $main_blue;
      }
      .selected{
        background-image:url(~@/assets/images/dialog/send_options_selected.png);
      }
    }
    .content{
      font-size: 14px;
      line-height: 28px;
      color: #666;
      padding-right: 12px;
    }
    .selected{
      @include background(14px,14px);
      width: 20px;
      height: 20px;
    }
  }
}
</style>
<style>
.dialog_fun_chat_page .el-textarea__inner{
  font-family: 'MicroSoft YaHei'!important;
  font-size: 14px!important;
  line-height: 20px!important;
}
</style>
<style lang="css">
.dialog_fun_chat_page .el-textarea__inner {
  height: 100% !important;
  border: none;
  padding: 0;
  font-size: 16px;
  
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  text-align: justify;
  text-justify: inter-ideograph;
  text-align-last: left;
}
.dialog_fun_chat_page .el-textarea__inner::-webkit-scrollbar {
  width: 0 !important;
}
</style>