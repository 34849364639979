<!--
 * @Author       : Hugo
 * @Date         : 2020-12-24 10:45:29
 * @LastEditTime : 2022-03-18 14:31:55
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/dialog/Sidebar.vue
 * @Description  : 侧边栏
 * @^_^          : 
-->
<template>
  <div class="dialog_sidebar_notice_page" @click="closeSidebar();">
    <!-- 关闭 -->
    <div
      class="close_btn cp"
      @click.stop="closeSidebar();"
    >
      <div class="close"></div>
    </div>
    <!-- 侧边栏 -->
    <div class="sidebar_notice_wrapper" @click.stop="doNothing($event)">
      <!-- 粘性栏 [样式未写] -->
      <div class="sticky_bar_group" v-if="0">
        <div class="switch_delete_multiple">批量删除</div>
      </div>
      <div class="sidebar_list_group" v-if="news_list && news_list.length">
        <div class="sidebar_item_group" v-for="item in news_list" :key="item.id">
          <div class="sidebar_item">
            <div
              class="dot"
              v-if="!item.isread"
            ></div>
            <div class="info_group">
              <div class="avatar_group">
               
                <avatar-detail
                  :msg_data="getAvatarData(item)"
                ></avatar-detail>
              </div>
              <div class="details_group">
                <div class="type_group">
                  <!-- <div class="type_name">{{transformNewsType(item)}}</div> -->
                  <div class="type_name">{{item.userName}}</div>
                  <div class="time">{{item.updateTime | universalDate}}</div>
                </div>
                <div class="details_box">{{transformDetails(item)}}</div>
                <div class="task_group"
                  v-if="item.type == 4"
                >任务: {{item.remind && item.remind.taskName}}</div>
                <div class="widget_group"
                  v-if="item.type == 4"
                >控件: {{(item.remind && item.remind.conName != '') ?  item.remind.conName : '未命名控件'}}</div>
              </div>
            </div>
            <div
              class="fun_group"
            >
              <button
                class="button btn blue"
                v-if="item.type == 4"
                @click="leaveFor(item)"
              >前往查看</button>
              <button 
                class="button btn"
                v-if="0"
              >查看详情</button>
              <button 
                class="button btn"
                v-if="0"
              >回复</button>
              <button 
                class="button btn"
                v-if="0"
              >拒绝</button>
              <button 
                v-if="0"
                class="button btn"
                @click="deleteNews(item)"
              >删除</button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="no_news"
        v-else
      >没有通知</div>
    </div>
    
  </div>
</template>
<script>
import AvatarDetail from '@/components/basic/avatarDetail.vue';
export default {
  components:{
    AvatarDetail,
  },
  data(){
    return {
      news_list: [],
    }
  },
  computed:{
    chat_type(){
      let chatType = this.$tools.getChatType();
      return chatType
    },
    chat_id(){
      // let chatId = this.$route.params.id;
      let chatId = this.$api.moduleTask.getRouteParamsId();
      return chatId;
    },
    dialog_info(){
      let {chat_type, chat_id} = this;
      return {chat_type, chat_id}
    },
  },
  watch: {
    /** 检测是否切换页面 */
    dialog_info:{
      handler: async function(val,old){
        let is_change = !(val.chat_type == old.chat_type && val.chat_id == old.chat_id);
        if(is_change){
          this.getSidebarList();
        }

      },
      deep: true,
    }
  },
  methods: {
    /**
     * @description: 关闭
     * @param {*}
     * @return {*}
     */
    closeSidebar(){
      this.$store.commit('set_switch_sidebar', false);
    },
    /**
     * 获取侧边栏列表
     */
    async getSidebarList(){
      let {chat_type, chat_id} = this;
      let data = {
        // startId: 0,
        chatType: chat_type,
        chatId: chat_id,
      }
      let res;
      try{
        res = await this.$http.request('/projectNews/queryNews', data);
        // console.log(res);
        if(res.message == 'success'){
          let contents = res.contents;
          let newsList = contents.newsList;
          if(newsList && newsList.length){
            newsList = this.transformNewsList(newsList);
          }
          this.$set(this, 'news_list', newsList);
        }else{
          this.$notice({title: res.describe});
        }
      }catch(err){
        // console.log(err);
      }
    },
    /**
     * @description: 获取新消息列表时处理数据
     * @param {*} list
     * @return {*}
     */
    transformNewsList(list){
      list = this.$tools.deepClone(list);
      list.forEach( item => {
        item.updateUserId = item.userId;
      });
      return list;

    },
    /**
     * @description: 转换消息类型
     * @param {*} type
     * @return {*}
     */
    transformNewsType(item){
      const {type, userName} = item;
      let out_type = '';
      switch(type){
        case 1:
          out_type = '修改工作组名称';
          break;
        case 2:
          out_type = '更新公示板';
          break;
        case 3:
          out_type = '成员变动';
          break;
        case 4:
          out_type = userName;
          break;
        default:
          out_type = '通知';
          break;
      }
      return out_type;
    },

    transformDetails(item){
      const {content, type} = item;
      if(type == 4){
        return `提醒: ${content}`;
      }
      return content;
    },
    
    async init(){
      await this.getSidebarList();
      // 置空侧边栏消息数
      const chat_detail = this.$tools.deepClone(this.$store.state.chat_detail);
      if(chat_detail&& chat_detail.chat){
        chat_detail.chat.sidebarflag = 0;
      }
      this.$store.commit('set_chat_detail', chat_detail);
    },
    /**
     * @description: 删除通知
     * @param {*} item
     * @return {*}
     */
    async deleteNews(item){
      const {id} = item;
      const data = {
        ids: id,
      }
      let res;
      try{
        res = await this.$http.request('/projectNews/deleteNews', data);
        if(res.message == 'success'){
          this.$notice({desc: '删除成功', type:'success'});
          const {news_list} = this;
          if(news_list && news_list.length){
            const index = news_list.findIndex( unit => {
              return unit.id == item.id;
            })
            if(index != -1){
              news_list.splice(index, 1);
            }
          }
        }else{
          this.$notice({desc: res.describe});
        }
      }catch(err){
        // console.log(err);
      }
    },
    doNothing(event){
      // event.stopPropagation();
      return;
    },
    leaveFor(item){
      // console.log(item);
      this.$api.moduleTask.openTask({
        taskId: item.remind.taskId,
        config: {
          edit: true,
          appoint_widget: {
            widget_id: item.remind.conId,
          },
          task_log: { //高亮 模拟taskLog
            details: [
              {
                conId: item.remind.conId,
              }
            ]
          }
        }
      });
      if(!item.isread){
        this.$api.reminder.readed(item.remind);
        this.$set(item, 'isread', true);
      }
    },
    /**
     * 处理用户头像数据
     */
    getAvatarData(item){
      return Object.assign(
        {},
        item,
        {
          userId: item.byUserId ?? item.userId, // 如果是提醒 则使用byUserId
        }  
      )
    }
  },
  async mounted(){
    await this.init();
  }

}
</script>
<style lang="scss" scoped>
  .dialog_sidebar_notice_page{
    @include flex;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    background-color: rgba(0,0,0,0.3);
    .sidebar_notice_wrapper{
      @include bbox;
      @include scrollbar;
      width: 442px;
      height: 100%;
      // background-color: red;
      background-color: #fff;
      border-bottom: 2px solid $border_color;
      padding: {
        left: 10px;
        right: 20px;
      }
      overflow-y: auto;
      overflow-x: hidden;
      .sticky_bar_group{
        width: 100%;
        background-color:#fff;
        position: sticky;
        top:0;
        left:0;
      }
      .sidebar_list_group{
        @include bbox;
        padding: 22px 0;
        .sidebar_item_group{
          @include transition;
          @include bbox;
          padding: 20px;
          border-bottom: 1px solid #f4f4f4;
          margin: 10px 0;
          &:hover{
            background-color: #eee;
          }
          .sidebar_item{
            position: relative;
            z-index: 2;
            .dot{
              position: absolute;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: $red_dot;
              top: -4px;
              left: -4px;
              z-index: 3;
            }
            .info_group{
              @include flex;
              align-items: flex-start;
              overflow: hidden;
              position: relative;
              z-index: 2;
              .avatar_group{
                position: relative;
                width: 44px;
                height: 44px;
                margin-right: 13px;
                background-color: #ccc;
                
              }
              .details_group{
                width: 100%;
                flex: 1;
                .type_group{
                  @include flexsb;
                  width: 100%;
                  font-size: 12px;
                  color: #666;
                }
                .details_box{
                  word-break: break-all;
                  padding-top: 10px;
                  font-size: 14px;
                  color:#333;
                  line-height: 20px;
                }
              }
              .task_group,
              .widget_group{
                font-size: 14px;
                color: #999999;
                line-height: 19px;
                padding-top: 10px;
              }
              
            }
            .fun_group{
              @include bbox;
              @include flex;
              padding-top: 30px;
              justify-content: flex-end;
              .btn{
                cursor: pointer;
                margin-left: 19px;
                font-size: 14px;
                text-decoration: underline;
                &.blue{
                  color: $main_blue;
                }
              }
            }
          }
          
        }
      }
      .no_news{
        @include flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        font-size: 14px;
        letter-spacing: 2px;
        color:#c2c2c2;
      }
    }
    .close_btn{
      width: 37px;
      height: 81px;
      .close{
        @include background;
        width: 100%;
        height: 100%;
        background-image: url(~@/assets/images/dialog/sidebar_close_btn.png);
      }
    }
  }
</style>
