var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog_fun_chat_page bbox d-flex" },
    [
      _c(
        "div",
        { staticClass: "input_group" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.is_appoint_task_group,
                  expression: "is_appoint_task_group",
                },
              ],
              staticClass: "appoint_task_group d-flex align-center",
            },
            [
              _c("div", { staticClass: "text" }, [_vm._v("回复任务: ")]),
              _c("div", { staticClass: "task_name" }, [
                _vm._v(_vm._s(_vm.task_name)),
              ]),
              _c("div", {
                staticClass: "close cp",
                on: {
                  click: function ($event) {
                    return _vm.closeAppointTask()
                  },
                },
              }),
            ]
          ),
          _c("el-input", {
            ref: "editor",
            staticClass: "editor",
            attrs: {
              type: "textarea",
              rows: 3,
              placeholder: _vm.editor_placeholder,
              resize: "none",
            },
            on: {
              blur: function ($event) {
                return _vm.handleBlur($event)
              },
            },
            nativeOn: {
              keydown: [
                function ($event) {
                  return _vm.handleKeydown($event)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.doEnter($event)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  if (!$event.ctrlKey) return null
                  return _vm.doEnterControl($event)
                },
              ],
              input: function ($event) {
                return _vm.handleKeyup($event)
              },
            },
            model: {
              value: _vm.editor_content,
              callback: function ($$v) {
                _vm.editor_content = $$v
              },
              expression: "editor_content",
            },
          }),
          _c(
            "div",
            { staticClass: "send_group flex-shrink-0" },
            [
              _c(
                "button",
                {
                  staticClass: "send button",
                  on: {
                    click: function ($event) {
                      return _vm.doSend()
                    },
                  },
                },
                [_vm._v("发布")]
              ),
              _c(
                "el-popover",
                {
                  attrs: { placement: "top-end", trigger: "click" },
                  model: {
                    value: _vm.switch_send_options,
                    callback: function ($$v) {
                      _vm.switch_send_options = $$v
                    },
                    expression: "switch_send_options",
                  },
                },
                [
                  _c("div", { staticClass: "send_option_group" }, [
                    _c(
                      "div",
                      {
                        staticClass: "options",
                        class: { on: !_vm.send_type },
                        on: {
                          click: function ($event) {
                            _vm.send_type = 0
                            _vm.switch_send_options = false
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "content" }, [
                          _vm._v("按Enter发布讨论内容"),
                        ]),
                        _c("div", { staticClass: "selected" }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "options",
                        class: { on: _vm.send_type },
                        on: {
                          click: function ($event) {
                            _vm.send_type = 1
                            _vm.switch_send_options = false
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "content" }, [
                          _vm._v("按Ctrl+Enter发布讨论内容"),
                        ]),
                        _c("div", { staticClass: "selected" }),
                      ]
                    ),
                  ]),
                  _c("div", {
                    staticClass: "option_btn",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.switch_at
        ? _c("at-pop-up", {
            attrs: {
              chat_type: Number(_vm.$tools.getChatType()),
              chat_id: Number(_vm.$global.decodeBase64(_vm.$route.params.id)),
              task_id: _vm.task_id,
              title: _vm.task_id
                ? "从任务参与人中选择@对象"
                : "从工作组中选择@对象",
            },
            on: {
              handleClose: _vm.closeAt,
              handleSure: _vm.addAt,
              handleAll: _vm.atAll,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }