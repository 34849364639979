import store from '@/store'

class MyTaskListApi {
  /**
   * 移除任务
   * @param params
   * @param {Number} params.taskId
   */
  removeTask = (params) => {
    try {
      const { taskId } = params
      const { my_task_list } = store.state
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const myTaskListIndex = my_task_list.findIndex((unit) => {
          return Number(unit.id) === Number(taskId)
        })
        if (myTaskListIndex !== -1) {
          my_task_list.splice(myTaskListIndex, 1)
        } else {
          break
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
}

const myTaskListApi = new MyTaskListApi()

export default myTaskListApi
