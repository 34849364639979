<template>
  <div
    class="components_dialog_operating_space_task_search_page d-flex align-end justify-space-between bbox"
    
  >
    <div class="list d-flex align-start">
      <div class="search_group flex-shrink-0 d-flex align-center bbox"
        :class="{active: switch_task}"
        ref="search"
      >
        <div class="icon"
          @click="changeSwitch"
        ></div>
        <input
          type="text"
          class="search bbox"
          placeholder="搜索"
          v-model="keyword"
          ref="keyword"
          @keydown.enter="doSearch($event)"
          @click="changeSwitch"
        >
        <div class="clear cp"
          @click="clearKeyword();"
          v-show="keyword.trim() != ''"
        ></div>
      </div>
      <div class="tag_list d-flex align-center flex-wrap"
        ref="wrapper"
        :class="{active: switch_task}"
        v-if="0"
      >
        <div class="tag cp flex-shrink-0"
          ref="tag"
          @click="touchAll();"
          :class="{active:all_status}"
        >全部</div>
        <template
          v-for="(item,index) in tag_list"
        >
          <div
            :key="index"
            class="tag cp flex-shrink-0"
            :class="{active:item.active}"
            ref="tag"
            @click="touchTag(item)"
          >{{item.tagName}}</div>
        </template>
      </div>
    </div>
    <!-- <div class="more flex-shrink-0 cp"
      v-if="tag_max_num"
      @click="switch_more=!switch_more"
    >更多...</div> -->
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: function(){
        return [];
      }
    },
    switch_task: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    list: function(){
      this.init();
    }
  },
  computed: {
    all_status(){
      const {tag_list} = this;
      let all_status = true;
      tag_list.forEach(item=>{
        if(item.active){
          all_status = false;
        }
      })
      return all_status;
    },
    tag_id(){
      const {tag_list} = this;
      let tag_id = '';
      tag_list.forEach(item=>{
        if(item.active){
          tag_id += tag_id ? `,${item.id}` : item.id;
        }
      })

      return tag_id;
    }
  },
  data(){
    return{
      switch_more: false,
      tag_max_num: null,
      tag_list: [],
      keyword: '',
    }
  },
  methods: {
    init(){
      this.$set(this, 'tag_list', this.$tools.deepClone(this.list))
      // this.$nextTick(function(){
      //   const wrapper = this.$refs.wrapper;
      //   const wrapper_width = wrapper.clientWidth;
      //   let tag = this.$refs.tag;
      //   for (let i in tag) {
      //     const offsetWidth = tag[i].offsetWidth;
      //     const offsetLeft = tag[i].offsetLeft;
      //     if(wrapper_width < (offsetWidth+offsetLeft )){
      //       if(this.tag_max_num == null){
      //         this.tag_max_num = i;
      //       }
      //     }
      //   }
      // })
    },
    changeSwitch(){
      const {switch_task} = this;
      if(!switch_task){
        this.$emit('handleChangeSwitch', true);
      }
    },
    clear(){
      this.keyword = '';
    },
    doSearch(e){
      // 中文输入法回车
      const is_chinese = this.chineseHandler(e);
      if(is_chinese){
        return false;
      }
      this.$emit('handleChange', {keyword:this.keyword.trim()});
    },
    chineseHandler(e){
      let status = false;
      if(e.keyCode == 229){
        status = true;
      }
      return status;
    },
    clearKeyword(){
      this.keyword = '';
      this.$emit('handleChange', {keyword:this.keyword.trim()});
    },
    touchTag(item){
      this.$set(item, 'active', !item.active);
      this.$emit('handleChange', {tag_id:this.tag_id});
    },
    touchAll(){
      const {tag_list} = this;
      tag_list.forEach(item=>{
        if(item.active){
          this.$set(item, 'active', false);
        }
      })
      this.$emit('handleChange', {tag_id:''});
    },
  },
  mounted(){
    this.init();
  }

}
</script>

<style lang="scss" scoped>
.components_dialog_operating_space_task_search_page{
  // width: 100%;
  width  : 245px;
  padding: 0 5px;
  .list{
    flex: 1;
  }
  .search_group{
    padding      : 0 16px;
    height       : 36px;
    background   : #F0F0F0;
    border-radius: 10px;
    font-size    : 12px;
    line-height  : 26px;
    border       : 1px solid #F0F0F0;
    position     : relative;
    width        : 245px;
    margin: {
      top: 5px;
      // right: 10px;
      bottom: 5px;
    }
    &:hover{
      border: 1px solid $main_blue;
    }
    &.active{
      background-color: #fff;
      // border: 1px solid #ccc;
      .search{
        width        : 200px;
        padding-right: 30px;
      }
    }
    .search{
      @include placeholder(#ccc);
      line-height     : 36px;
      font-size       : 14px;
      background-color: transparent;
      border          : none;
      outline         : none;
      padding         : 0 10px;
      width           : 50px;
      width           : 100%;
      flex            : 1;
      padding: {
        left: 10px;
      }
    }
    .icon{
      width           : 12px;
      height          : 12px;
      background-image: url(~@/assets/images/operating_space/search.png);
    }
    .clear{
      @include transition;
      position        : absolute;
      top             : 50%;
      right           : 10px;
      transform       : translateY(-50%);
      width           : 14px;
      height          : 14px;
      background-image: url(~@/assets/images/operating_space/clear.png);
    }
  }
  .tag_list{
    @include scrollbar;
    max-height: 38px;
    overflow  : hidden;
    flex      : 1;
    &.active{
      max-height: 76px;
      overflow-y: auto;
    }
  }
  .tag{
    height          : 28px;
    background-color: #E9E9E9;
    border-radius   : 16px;
    padding         : 0 12px;
    line-height     : 28px;
    font-size       : 14px;
    font-size       : 12px;
    color           : #555;
    margin: {
      top   : 5px;
      right : 10px;
      bottom: 5px;
    }
 
    &.active{
      color           : $main_blue;
      background-color: #E6EEF7;
    }
  }
  .more{
    font-size   : 12px;
    color       : $main_blue;
    padding-left: 10px;
    line-height : 38px;
  }
}
</style>