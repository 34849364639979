<template>
  <div
    class="views_fun_task_public_notice_page bbox d-flex align-stretch"
  >
    <div class="main_group bbox d-flex flex-column">
      <Main
        :select_target="select_target"
        :public_notice="public_notice"
        class="main"
        :editable="is_lord"
        :view="view"
        ref="main"
        @handleCall="callTask"
      ></Main>
      <div class="send_group bbox d-flex align-center"
        v-if="fun_status"
      >
        <div
          class="button send btn_dark"
          @click="doSend"
        >
          发布
        </div>
        <div
          class="button cancel btn_light"
          @click.stop="doClose"
        >
          取消
        </div>
      </div>
    </div>
    <side-bar
      class="flex-shrink-0 side_bar"
      :type="sidebar_type"
      :public_notice="public_notice"
      :editable="editable"
      @handleChangePannel="changePannel"
      @handleAddNewWidget="addNewWidget"
      @handleSwitchSidebar="switchSidebar"
      @handleSavaTask="handleSavaTask"
      v-if="is_lord"
    ></side-bar>
    <div
      class="close_btn cp"
      @click.stop="doClose"
    >
      <div class="text">关闭公示板</div>
      <img src="@/assets/images/dialog/announcement_close.png" alt="" class="switch close">
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Main from '@/components/Task/Main.vue'
import SideBar from '@/components/Task/SideBar.vue'
export default {
  components: {
    Main,
    SideBar,
  },
  data(){
    return{
      sidebar_type: 'widget',
      public_notice: true,
    }
  },
  computed: {
    task () {
      return this.$store.state.task
    },
    select_target () {
      return this.$store.state.task_config.select_target
    },
    editable () {
      const {task_config} = this.$store.state
      const {editable} = task_config
      return editable
    },
    view () {
      const {task_config} = this.$store.state
      const {view} = task_config
      return view
    },
    is_lord () {
      const {state} = this.$store
      const {chat_detail} = state
      let is_lord = false
      // console.log(chat_detail)
      if (chat_detail && chat_detail.chat) {
        let lord_id = chat_detail.chat.createUserId;
        let userid = this.$tools.getUserId()
        if (lord_id == userid) {
          is_lord = true
        }
      }
      return is_lord;
    },
    is_modified () {
      const {state} = this.$store
      const {task} = state
      let is_modified = false
      const {taskDeatails} = task
      if (!taskDeatails) {
        return false
      }
      taskDeatails.forEach((unit) => {
        const { id, modified } = unit
        if (!id || modified) {
          is_modified = true
        }
        if (unit.controlLinkList && unit.controlLinkList.length) {
          unit.controlLinkList.forEach((link_unit) => {
            if (!link_unit.id || link_unit.modified) {
              is_modified = true
            }
          })
        }
      })
      return is_modified
    },
    fun_status () {
      const { is_lord, is_modified } = this
      if (!is_lord) {
        return false
      }
      return is_modified
    }
  },
  inject:['reload'],
  methods: {
    changePannel (type) {
      this.sidebar_type = type;
    },
    addNewWidget () {
      this.$refs.main.addNewWidget();
    },
    switchSidebar () {
      this.$refs.main.switchSidebar();
    },
    callTask (taskId) {
      this.$api.moduleTask.callTask({taskId});
    },
    handleSavaTask () {
      this.handleSend();
    },
    async handleSend () {
      const { task, task_config } = this.$store.state
      const { draft } = task_config
      if (draft) {
        await this.$api.moduleTask.sendDraft()
        return
      }
      if (task.task.taskType == 2) {
        await this.$api.moduleTask.createInstance()
        return
      }
      if (task.task.taskType == 3) {
        await this.$api.moduleTask.editInstance()
        return
      }
      await this.$api.moduleTask.sendTask()
    },
    async init () {
      // 获取创建者id $store.state.chat_detail;
      await this.$api.moduleTask.openPublicNotice({
        chat_id: this.$api.moduleTask.getRouteParamsId()
      })
      store.commit('set_annc_switch', true)
    },

    /**
     * @description: 关闭
     * @param {*}
     * @return {*}
     */
    doClose () {
      //TODO 关闭前判断是否编辑并提示
      const {fun_status} = this
      if(fun_status) {
        this.$openConfirm({
          title:'是否关闭?',
          desc:'该操作无法撤销',
          sure:'',
          cancel:'',
          onSure:()=>{
            this.$emit('handleClose')
          },
          onCancel:()=>{},
        })
        return
      }
      this.$emit('handleClose')
    },
    async doSend () {
      this.$showLoading({title:'发布中'})
      const res = await this.$api.moduleTask.editPublicNotice()
      this.$hideLoading()
      if (res.message === 'success') {
        this.$emit('handleClose')
        // this.reload();
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.views_fun_task_public_notice_page{
  width:100%;
  height: 100%;
  background-color: $main_bg_color;
  position: relative;
  z-index: 2;
  .main_group{
    flex:1;
  }
  .send_group{
    width: 100%;
    padding: 10px 20px;
    .button{
      line-height: 30px;
      padding:0 24px;
      border-radius: 4px;
      margin-right: 5px;
      font-size: 14px;
    }
  }
  .close_btn{
    @include u_sel_none;
    @include transition;
    position: absolute;
    z-index: 4;
    bottom:0;
    left: 50%;
    transform: translateX(-50%);
    .text{
      position: absolute;
      left: 20px;
      top:0;
      font-size: 12px;
      line-height: 22px;
      color: #fff;
    }
    .switch{
      display: block;
    }
  }
}
</style>
