<!--
 * @Author       : Hugo
 * @Date         : 2020-06-04 14:13:10
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-19 15:44:29
 * @Description  : 单聊抽屉
 * @FilePath     : /miaohang/src/components/dialog/drawerSingle.vue
--> 




<template>
  <div class="dialog_drawer_single_wrapper">
   
    <div class="user_list_group">
      <div class="list_group">
        <div class="add cp" @click="openAddContactToDialog"
          v-if="!isSystemUser()"
        >
          <img src="@/assets/images/dialog/drawer_add.png" alt="" class="img">
          <div class="text">添加</div>
        </div>
        <!-- <div class="unit" v-if="user_data && user_data.userId == $route.params.id"> -->
        <div class="unit" v-if="user_data && user_data.userId == $api.moduleTask.getRouteParamsId()">
          <WorkTeamDetailUnit width="44px" imgwidth="44px" imgheight="44px" placement="left-start" :userData="user_data"></WorkTeamDetailUnit>
        </div>
      </div>
    </div>
    <div
      class="switch_group"
      v-if="chat_detail && !isSystemUser()"
    >
      <div class="switch_line">
        <div class="text">消息免打扰</div>
        <div class="switch">
          <el-switch
            v-model="chat_detail.isdisturb"
            active-color="#0054A6"
            inactive-color="#DDDDDD"
            :active-value="1"
            :inactive-value="0"
            @change="changeDoNotDistrub">
          </el-switch>
        </div>
      </div>
      <div class="switch_line">
        <div class="text">置顶</div>
        <div class="switch">
          <el-switch
            v-model="chat_detail.istop"
            active-color="#0054A6"
            inactive-color="#DDDDDD"
            :active-value="1"
            :inactive-value="0"
            @change="changeIstop">
          </el-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PChatDetail} from '@/types';
import WorkTeamDetailUnit from '@/components/workteam/detailUnit.vue';
export default {
  components: {
    WorkTeamDetailUnit,
  },
  data(){
    return{
      stick: false, //置顶
      userId: this.$tools.getUserId(),
      // contact_data: this.$tools.getCookie('user'),
      // chat_detail: null,
    }
  },
  computed:{
    user_data(){
      return this.$store.state.user_data;
    },
    chat_detail(){
      return this.$store.state.chat_detail;
    },
   
  },
  watch: {
    user_data: {
      handler: function(){
        this.userdataHandler();
      },
      deep: true,
    },
    '$route.params.id': function(){
      this.userdataHandler();
    },
  },
  async mounted(){
    this.userdataHandler();
    this.init();
  },
  methods: {
    /**
     * @description: 添加好友到会话
     * @param {type} 
     * @return: 
     */
    openAddContactToDialog(){
      // const already = this.$api.user.membershipPrivileges({hint:'该操作为会员权益，当前用户暂无权限'});
      // if(already){
        this.$api.common_set_add_contact_to_dialog_box();
        this.$emit('closeDrawer');
      // }
    },
    /**
     * 用户信息判断
     * 不是当前用户需要重新获取
     */
    async userdataHandler(){
      // let routerid = this.$route.params.id;
      let routerid = this.$api.moduleTask.getRouteParamsId();
      if(this.user_data && this.user_data.userId == routerid){
        return;
      }else{
        this.$store.dispatch('get_user_data', routerid);
      }
    },
    async init(){
      if(this.$route.name == 'fun_task_dialog_single'){
        await this.getChatDetails();
      }
    },
    /**
     * @description: 获取聊天详情
     * @param {*}
     * @return {*}
     */
    async getChatDetails(){
      // let chatId = this.$route.params.id;
      let chatId = this.$api.moduleTask.getRouteParamsId();
      let data = {
        chatType: 0,
        chatId,
      }
      let res;
      try{
        res = await this.$http.request('/projectTask/chatDetail', data);
        if(res.contents.PChatDetail){
          this.$store.commit('set_chat_detail', new PChatDetail(res.contents.PChatDetail));
          // this.$set(this, 'chat_detail', res.contents.PChatDetail);
          // // console.log(res.contents.PChatDetail);
          // this.$set(this.chat_detail, 'istop', res.contents.PChatDetail.istop.toString() )
        }
      }catch(err){
        // console.log(err);
      }

    },
    /**
     * @description: 改变置顶
     * @param {*}
     * @return {*}
     */
    async changeIstop(){
      let {chatType, chatId, istop} = this.chat_detail;
      await this.$api.setMessageTop({chatType, chatId, istop});
    },
    changeDoNotDistrub(){
      this.chat_detail.changeDoNotDistrub();
    },
    // 是否系统用户
    isSystemUser(){
      // const userid = this.$route.params.id;
      const userid = this.$api.moduleTask.getRouteParamsId();
      let status = false;
      status = this.$api.isSystemUser(userid);
      return status;
    }

  }
  
}
</script>

<style lang="scss" scoped>
.dialog_drawer_single_wrapper{
  @include hidescrollbar;
  width:100%;
  height: 100%;
  overflow-y: auto;
  outline: none;
  .user_list_group{
    @include bbox;
    padding: 10px 0;
    border-bottom: 2px solid $border_color;
    .list_group{
      @include flex;
      @include bbox;
      flex-wrap: wrap;
      align-items: flex-start;
      .add{
        flex-shrink: 0;
        width:44px;
        margin: {
          top: 20px;
          right: 20px;
        }
        .img{
          display: block;
          width: 44px;
          height: 44px;
        }
        .text{
          padding-top: 9px;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size:14px;
          
          font-weight:400;
          color:rgba(101,101,101,1);
        }
      }
      .unit{
        flex-shrink: 0;
        margin: {
          top: 20px;
          right: 20px;
        }
        
        &:nth-of-type(4n){
          margin-right: 0;
        }
      }
    }
    .more_group{
      @include flex;
      justify-content: center;
      padding: {
        top:29px;
        bottom: 9px;
      }
      .more{
        font-size:14px;
        
        font-weight:400;
        color: $main_blue;
        padding-right: 5px;
      }
      .icon{
        width: 10px;
        height: 6px;
        background: {
          image: url(~@/assets/images/dialog/more_icon.png);
          size: 100% 100%;
        }
      }
    }
    
  }

  .switch_group{
    width: 100%;
    padding: 20px 0;
    border-bottom: 2px solid $border_color;
    .switch_line{
      @include flexsb;
      padding-bottom: 20px;
      &:last-child{
        padding-bottom: 0;
      }
      .text{
        font-size:16px;
        
        font-weight:400;
        color:rgba(51,51,51,1);
      }
    }
  }

}
</style>
