
<template>
  <div class="dialog_drawer_wrapper"
    @click.stop="$api.stopPropagation($event);"
  >
    <div class="info_section">
      <div class="group_nickname" v-if="1">
        <div class="title">工作组名称</div>
        <div class="input_group">
          <input
            type="text"
            class="group_name"
            :class="{on: group_name_focus}"
            ref="group_name"
            :maxlength="chatname_max_length"
            @focus="group_name_focus=true;"
            @keyup.enter="$refs.group_name.blur()"
            @blur="group_name_focus=false;dochangeChatName()"
            tabindex="-1"
            v-model="chat_name">
        </div>
      </div>
      <div class="group_nickname" v-if="0">
        <div class="title">我在群组内的昵称</div>
        <div class="input_group">UTEC-张立(功能暂未开通)</div>
      </div>
      <div class="user_list_group">
        <div class="list_group">
          <div class="add cp" @click="openAddContactToDialog">
            <img src="@/assets/images/dialog/drawer_add.png" alt="" class="img">
            <div class="text">添加</div>
          </div>
          <div class="add cp"
            v-if="is_create_user"
            @click="startRemove();"
          >
            <img src="@/assets/images/dialog/drawer_remove.png" alt="" class="img">
            <div class="text">移除</div>
          </div>
          <template
            v-if="dialog_user_list && dialog_user_list.length"
          >
            <div
              class="unit"
              v-for="(item, index) in dialog_user_list"
              :key="index"
            >
              <WorkTeamDetailUnit
                v-if="index < chatUserNumberLimit"
                width="40px"
                imgwidth="40px"
                imgheight="40px"
                fontsize="12px"
                placement="left-start"
                :userData="UserInfo(item)"
              ></WorkTeamDetailUnit>
            </div>
          </template>
          <template
            v-else
          >
            <div class="load_user_list un_sel">加载中...</div>
          </template>
        </div>
        <div
          v-show="showMoreUser"
          class="more_group"
        >
          <div
            class="more cp"
            @click="switchMoreUser = !switchMoreUser"
          >{{ switchMoreUser ? '收起' : '查看全部' }}({{ dialog_user_list.length }})</div>
          <div
            v-if="0"
            class="icon"
          ></div>
        </div>
      </div>
      <div class="fun_group" v-if="0">
        <div class="fun publicity">
          <img src="@/assets/images/dialog/publicity.png" alt="" class="img">
          <div class="name">信息公示</div>
        </div>
        <div class="fun free_form">
          <img src="@/assets/images/dialog/free_form.png" alt="" class="img">
          <div class="name">自由表单</div>
        </div>
      </div>
      <div class="group_nickname" v-if="1">
        <div class="title">我在群组内的昵称</div>
        <div class="input_group">
          <input
            type="text"
            class="group_name"
            :class="{on: group_name_focus}"
            :maxlength="chatname_max_length"
            placeholder="点击设置昵称"
            @blur="changeChatUsername()"
            @keyup.enter="changeChatUsername()"
            v-model="chat_username">
        </div>
      </div>
      <div
        v-if="chat_detail.chat.courceTitle"
        class="course_wrapper d-flex align-center justify-space-between"
      >
        <div
          class="title flex-shrink-0"
        >
          课程
        </div>
        <div
          class="course cp"
          @click="viewCoursePackage"
        >
          {{ chat_detail.chat.courceTitle }}
        </div>
      </div>
      <div class="switch_group"  v-if="chat_detail">
        <div class="switch_line" v-if="0">
          <div class="text">仅看与我有关的任务单</div>
          <div class="switch">
            <el-switch
              v-model="onlyme"
              active-color="#0054A6"
              inactive-color="#DDDDDD">
            </el-switch>
          </div>
        </div>
        <div class="switch_line" v-if="0">
          <div class="text">消息免打扰</div>
          <div class="switch">
            <el-switch
              v-model="dontDisturb"
              active-color="#0054A6"
              inactive-color="#DDDDDD">
            </el-switch>
          </div>
        </div>
        <div class="switch_line">
          <div class="text">消息免打扰</div>
          <div class="switch">
            <el-switch
              v-model="chat_detail.isdisturb"
              active-color="#0054A6"
              inactive-color="#DDDDDD"
              :active-value="1"
              :inactive-value="0"
              @change="chat_detail.changeDoNotDistrub">
            </el-switch>
          </div>
        </div>
        <div class="switch_line">
          <div class="text">置顶</div>
          <div class="switch">
            <el-switch
              v-model="chat_detail.istop"
              active-color="#0054A6"
              inactive-color="#DDDDDD"
              :active-value="1"
              :inactive-value="0"
              @change="changeIstop">
            </el-switch>
          </div>
        </div>
      </div>
      <div class="radio_group" v-if="0">
        <div class="radio_line">
          <div class="text">强制模板内容对所有组员可见</div>
          <div class="radio_box" :class="{on: everybodyCanSee == true}" @click="setDialogAuthority('everybodyCanSee')">
            <div class="radio"></div>
          </div>
        </div>
        <div class="radio_line">
          <div class="text">强制模板内容仅模板成员可见</div>
          <div class="radio_box" :class="{on: memberCanSee == true}" @click="setDialogAuthority('memberCanSee')">
            <div class="radio"></div>
          </div>
        </div>
        <div class="radio_line">
          <div class="text">允许成员自行设定</div>
          <div class="radio_box" :class="{on: custom==true}" @click="setDialogAuthority('custom')">
            <div class="radio"></div>
          </div>
        </div>
      </div>
    </div>
    <!--  [删除工作组]未开放测试 -->

    <div
      v-if="1"
      class="btn_section"
    >
      <button
        v-if="is_create_user"
        class="dissolve btn btn_light button cp"
        @click.stop="touchMigrateTaskSelectTask"
      >
        任务迁移
      </button>
      <button
        class="button btn_light"
        @click.stop="exitWorkingTeam()"
      >
        退出工作组
      </button>
      <button
        v-if="is_create_user"
        class="dissolve btn btn_light button cp"
        @click.stop="doDeleteChat"
      >
        解散工作组
      </button>
    </div>

  </div>
</template>

<script>
import global from '@/api/global'
import {PChatDetail, UserInfo} from '@/types';
import WorkTeamDetailUnit from '@/components/workteam/detailUnit.vue';
import store from '@/store'
import CoursePackage from '@/models/cource_package'
export default {
  components: {
    WorkTeamDetailUnit,
  },
  data(){
    return{
      userList: null,
      onlyme: false, // 近查看与我有关
      dontDisturb: false,
      stick: false, //置顶
      everybodyCanSee: false, // 所有人可见
      memberCanSee: false, // 模板成员可见
      custom: false, // 成员自行设定
      group_name_focus: false,
      chat_name: '', // 群聊名称
      // chat_detail: null,
      chat_username: '',
      switchMoreUser: false,
    }
  },
  computed:{
    showMoreUser () {
      const {
        is_create_user,
        dialog_user_list,
      } = this
      if (is_create_user) {
        if (dialog_user_list.length > 10) {
          return true
        }
      } else {
        if (dialog_user_list.length > 11) {
          return true
        }
      }
      return false
    },
    chatUserNumberLimit () {
      const {
        is_create_user,
        dialog_user_list,
        switchMoreUser,
      } = this
      if (switchMoreUser) {
        return dialog_user_list.length
      }
      if (is_create_user) {
        return 10
      } else {
        return 11
      }

    },
    /**会话联系人 */
    dialog_user_list(){
      return this.$store.state.dialog_user_list;
    },
    dialog_data(){
      return this.$store.state.dialog_data;
    },
    chat_detail(){
      return this.$store.state.chat_detail;
    },
    // 是否创建者
    is_create_user(){
      let is_create_user = false;
      let {chat_detail} = this;
      let userid = this.$tools.getUserId();
      if(chat_detail && chat_detail.chat && chat_detail.chat.createUserId){
        if( chat_detail.chat.createUserId == userid){
          is_create_user = true;
        }
      }
      return is_create_user;
    },
    /**
     * 控制最大字符
     */
    chatname_max_length(){
      let num = 40;
      let {chat_name} = this;
      let reg = /[^\x00-\x80]/g;
      let chinese_arr = chat_name.match(reg);
      // // console.log(chinese_arr);
      if(chinese_arr && chinese_arr.length){
        // 有一个中文字符 最大字符数减1
        num = num - chinese_arr.length;
      }
      // // console.log(num);
      return num;
    },
  },
  watch: {
    dialog_user_list: {
      handler: function(){},
      deep: true,
    },
    dialog_data:{
      handler:function(val){
        this.chat_name = val.chatName;
      }, deep:true,
    },
    group_name_focus:function(){},
  },
  mounted() {
    this.userList = [
      {"userId":1,"userType":1,"userStatus":1,"loginName":"18600195110","userPassword":"96e79218965eb72c92a549dd5a330112","userToken":"e5cb4262ac3dacf7cd435995d39711be","userName":"武明 ","userRealName":null,"userSex":null,"companyFullname":null,"ownedEnterprises":null,"userPosition":null,"companyUserId":null,"channelName":null,"hxPwd":"123456","phone":18600195110,"email":"","thumbnail":"2016/07/14/1468474876934.png","spaceBackgroundPath":null,"createTime":1448960615000,"updateTime":1487143406000,"personalDescribe":"手机铃声自信?","authStatus":null,"punishNum":0,"maxUserNums":0,"userIntegral":null,"userVip":0,"compRegNo":null,"compRunExtent":null,"compSetUpDate":null,"compScope":null,"compLicensePath":null,"compLinkmanName":null,"compLinkmanPhone":null,"compCheckStatus":null,"applyStatus":null,"listSkill":[],"vipStartDate":null,"vipEndDate":null,"openId":"olcQOt33TpXMQMA7yHHcI38tq9tk","threeOne":0,"provinceCoder":null,"coder":null,"address":null,"userCardNo":null},
      {"userId":22,"userType":1,"userStatus":1,"loginName":"19999999999","userPassword":"55587a910882016321201e6ebbc9f595","userToken":"7107f340f01bfeb8f3043e2b41c6ca1b","userName":"别理我","userRealName":null,"userSex":null,"companyFullname":null,"ownedEnterprises":null,"userPosition":"","companyUserId":null,"channelName":null,"hxPwd":"123456","phone":19999999999,"email":"123@123.com","thumbnail":"2018/10/12/1539317052937388.jpg","spaceBackgroundPath":null,"createTime":1451529258000,"updateTime":1451983897000,"personalDescribe":"生活就像一杯白开水","authStatus":null,"punishNum":0,"maxUserNums":0,"userIntegral":null,"userVip":0,"compRegNo":null,"compRunExtent":null,"compSetUpDate":null,"compScope":null,"compLicensePath":null,"compLinkmanName":null,"compLinkmanPhone":null,"compCheckStatus":null,"applyStatus":null,"listSkill":[],"vipStartDate":null,"vipEndDate":null,"openId":null,"threeOne":0,"provinceCoder":null,"coder":null,"address":null,"userCardNo":null
      }
    ];

    if(this.dialog_data){

      this.chat_name = this.dialog_data.chatName;
    }
    this.group_name_focus = false;

    this.init();
    console.log(this.chat_detail)
  },
  inject:['reload'],
  methods: {
    touchMigrateTaskSelectTask() {
      this.$openMigrateTaskSelectTask({
        pChatDetail: this.chat_detail
      })
    },
    exitWorkingTeam(){
      const chatId = Number(global.decodeBase64(this.$route.params.id))
      this.$openConfirm({
        title: `是否退出工作组?`,
        sure:'是',
        cancel:'否',
        onSure:async ()=>{
          this.$global.preventDuplicateClicks(
            async () => {
              let res = await this.$global.doPost({
                url: '/project/quitChat',
                data: {
                  chatId,
                },
                notice: '退出失败',
                loading: true,
                loadingText: '退出中'
              })
              if(res.message === 'success'){
                // 移除消息盒子
                const {message_box_list} = store.state
                const index = message_box_list.findIndex(item=>{
                  return Number(item.chatId) === chatId
                })
                if(index !== -1){
                  message_box_list.splice(index, 1)
                  // 跳转到index页
                  this.$router.push({name: 'fun_task_index'})
                }

                this.$emit('closeDrawer')
                this.reload();
              }
            }
          )
        },
        onCancel:()=>{},
      })
    },
    /**
     * 切换权限radio
     * @params {*} mode 模式名
     */
    setDialogAuthority(mode){

      let all = {
        everybodyCanSee: false,
        memberCanSee: false,
        custom: false,
      }
      // console.log(all)
      delete all[mode];
      Object.keys(all).map((key)=>{
        this.$set(this, key, all[key]);
      })
      this.$set(this, mode, true);
    },
    /**
     * @description: 添加好友到会话
     * @param {type}
     * @return:
     */
    openAddContactToDialog(){
      // const already_vip = this.$api.user.membershipPrivileges({hint:'您不是会员, 无法邀请新成员'});
      // if(already_vip){
        // this.$store.commit('set_add_contact_to_dialog_box', true);
        this.$api.common_set_add_contact_to_dialog_box();
        this.$emit('closeDrawer');
      // }

    },
    async dochangeChatName(){
      let {chat_name, dialog_data} = this;
      let chatId = dialog_data.chatId;
      let data = {chatId, chatName:chat_name};
      if(chat_name == ''){
        this.chat_name = dialog_data.chatName;
        this.$notice({desc: '工作组名不能为空'})
        return;
      }
      if(chat_name != dialog_data.chatName ){
        await this.$http.request('/project/chatNameEdit',data);
        this.$api.changeGroupName({chatId, chatName: chat_name})
        this.dialog_data.chatName = chat_name;
        // this.changeChatName();
        this.$toast({icon:'success/none',title:'',desc:'修改成功',duration:1000});
        this.reload();
      }

    },
     /**修改群聊会话名 */
    changeChatName(){
      if(!this.dialog_data){
        return;
      }
      let {chatId} = this.dialog_data;
      let message_box_list = this.$tools.deepClone(this.$store.state.message_box_list);
      let index = message_box_list.findIndex( item => {
        return item.chatId == chatId;
      })
      if(index != -1){
        message_box_list[index].name = this.chat_name;
      }
      // // console.log(message_box_list[index])
      this.$store.commit('set_message_box_list', message_box_list);
    },
    inputNameBlur(){
      setTimeout(()=>{
        this.$refs.group_name.blur();
      },20)
    },
    async init(){
      if(this.$route.name == 'fun_task_dialog_group'){
        await this.getChatDetails();
      }
    },
    /**
     * @description: 获取聊天详情
     * @param {*}
     * @return {*}
     */
    async getChatDetails(){
      // let chatId = this.$route.params.id;
      let chatId = this.$api.moduleTask.getRouteParamsId();
      let data = {
        chatType: 1,
        chatId,
      }
      let res;
      try{
        res = await this.$http.request('/projectTask/chatDetail', data);
        if(res.contents.PChatDetail){
          // this.$set(this, 'chat_detail', res.contents.PChatDetail);
          this.$store.commit('set_chat_detail', new PChatDetail(res.contents.PChatDetail));
          // this.$set(this.chat_detail, 'istop', res.contents.PChatDetail.istop.toString() )
          this.chat_username = res.contents.PChatDetail.chatUserName || ''; //群昵称
        }
      }catch(err){
        // console.log(err);
      }

    },
    /**
     * @description: 改变置顶
     * @param {*}
     * @return {*}
     */
    async changeIstop(){
      let {chatType, chatId, istop} = this.chat_detail;
      await this.$api.setMessageTop({chatType, chatId, istop});
    },
    /**
     * @description: 删除工作组
     * @param {*}
     * @return {*}
     */
    async doDeleteChat(){
      this.$openConfirm({
        title:'是否确认解散工作组?',
        desc:'解散后相关模板将被全部清除',
        sure:'是',
        cancel:'否',
        onSure: async ()=>{
          let {chat_detail} = this;
          await this.$api.deleteChat({id: chat_detail.chatId});
        }
      })
    },
    /**
     * @description: 更改我再群里的昵称
     * @param {*}
     * @return {*}
     */
    async changeChatUsername(){
      let {chat_username} = this;
      if( chat_username != this.chat_detail.chatUserName){
        let res ;
        let data = {
          chatUserName: chat_username,
          // chatId: this.$route.params.id,
          chatId: this.$api.moduleTask.getRouteParamsId(),
        }
        try{
          res = await this.$http.request('/project/modifyUserName', data);
          if(res.message == 'success'){
            this.$set(this.chat_detail, 'chatUserName', chat_username);
            this.$notice({desc: '修改成功'});
            this.updateChatUserName();
          }
        }catch(err){
          // console.log(err);
        }
      }
    },
    /**
     * @description: 更新群昵称信息
     * @param {*}
     * @return {*}
     */
    updateChatUserName(){
      let {chat_username, dialog_user_list} = this;
      let userid = this.$tools.getUserId();
      let userdata = dialog_user_list.find((item)=>{
        return item.userId == userid;
      })
      if(userdata){
        this.$set(userdata, 'chatUserName', chat_username);
      }
    },
    /**
     * @description: 开启移除群员
     * @param {*}
     * @return {*}
     */
    startRemove(){
      const {commit} = this.$store;
      commit('set_switch_popup_common', true);
      commit('set_popup_common_type', 'remove_chat_user');
    },
    UserInfo(item){
      const userinfo = new UserInfo(item);
      // console.log(userinfo);
      // console.log(userinfo.priorableName);
      return userinfo;
    },
    viewCoursePackage () {
      console.log(this.chat_detail)
      const coursePackage = new CoursePackage({
        id: this.chat_detail.chat.courceId
      })
      store.commit(
        'setCoursePackageData',
        coursePackage
      )
      this.$modal.show(
        'globalViewCrousePackage'
      )
    }
  },

}
</script>

<style lang="scss" scoped>
.dialog_drawer_wrapper{
  @include flexsb;
  flex-direction: column;
  width:100%;
  height: 100%;
  outline: none;
  .info_section{
    @include hidescrollbar;
    height: 100%;
    width:100%;
    flex: 1;
    overflow-y: auto;
  }
  .btn_section{
    @include bbox;
    padding-bottom: 10px;
    flex-shrink: 0;
    width: 100%;
    .button{
      font-size: 16px;
      // border:2px solid $main_blue;
      width: 100%;
      border-radius: 4px;
      line-height: 48px;
      // color:$main_blue;
      margin-top: 10px;
    }
  }
  .group_nickname{
    border-bottom: 2px solid $border_color;
    padding: 25px 0 10px;
    .title{
      @include bbox;
      padding: 0 5px;
      font-size:14px;
      padding-bottom: 10px;
      font-weight:400;
      color:rgba(153,153,153,1);
    }
    .input_group{
      padding:6px 0;
      font-size:16px;
      font-weight:400;
      color:rgba(51,51,51,1);
      .group_name{
        @include bbox;
        @include transition;
        padding: 0 5px;
        border-radius: 4px;
        width: 100%;
        border: 1px solid transparent;
        line-height: 36px;
        font-size: 16px;
        &:hover,&.on, &:focus{
          border: 1px solid rgba(153,153,153,1);
        }
      }
    }
  }
  .user_list_group{
    @include bbox;
    padding: 10px 0;
    border-bottom: 2px solid $border_color;
    .list_group{
      @include flex;
      @include bbox;
      flex-wrap: wrap;
      align-items: flex-start;
      .add{
        flex-shrink: 0;
        width:40px;
        margin: {
          top: 16px;
          right: 16px;
        }
        .img{
          display: block;
          width: 40px;
          height: 40px;
        }
        .text{
          padding-top: 9px;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size:12px;
          line-height: 20px;
          font-weight:400;
          color:rgba(101,101,101,1);
        }
      }
      .unit{
        flex-shrink: 0;
        margin: {
          top: 16px;
          right: 16px;
        }

        &:nth-of-type(4n){
          margin-right: 0;
        }
      }
    }
    .more_group{
      @include flex;
      justify-content: start;
      padding: {
        top:29px;
        bottom: 9px;
      }
      .more{
        font-size:14px;

        font-weight:400;
        color: $main_blue;
        padding-right: 5px;
      }
      .icon{
        width: 10px;
        height: 6px;
        background: {
          image: url(~@/assets/images/dialog/more_icon.png);
          size: 100% 100%;
        }
      }
    }
    .load_user_list{
      font-size: 14px;
      color: #666;
      line-height: 44px;
      margin-top: 20px;
    }

  }
  .fun_group{
    @include flex;
    align-items: flex-start;
    padding: {
      top: 20px;
      bottom: 20px;
    }
    border-bottom: 2px solid $border_color;
    .fun{
      width: 56px;
      margin-right: 36px;
      &:last-child{
        margin-right: 0;
      }
      .img{
        width:56px;
        height: 56px;
        display: block;
      }
      .name{
        padding-top:11px;
        font-size:14px;

        font-weight:400;
        color:rgba(51,51,51,1);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
  .course_wrapper {
    padding-top: 20px;
    .title {
      font-size: 16px;
      color: #333333;
      line-height: 21px;
      text-align: justify;
      font-style: normal;
      margin-right: 10px;
    }
    .course {
      font-weight: 400;
      font-size: 14px;
      color: #FF7200;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      padding: 3px 8px;
      background: rgba(255,114,0,0.08);
      border-radius: 2px;
      max-width: 160px;
    }
  }
  .switch_group{
    width: 100%;
    padding: 20px 0;
    border-bottom: 2px solid $border_color;
    .switch_line{
      @include flexsb;
      padding-bottom: 20px;
      &:last-child{
        padding-bottom: 0;
      }
      .text{
        font-size:16px;

        font-weight:400;
        color:rgba(51,51,51,1);
      }
    }
  }
  .radio_group{
    width: 100%;
    padding: 29px 0;
    .radio_line{
      @include flexsb;
      padding-bottom: 20px;
      &:last-child{
        padding-bottom: 0;
      }
      .text{
        font-size:16px;

        font-weight:400;
        color:rgba(51,51,51,1);
      }
      .radio_box{
        @include bbox;
        width:20px;
        height:20px;
        border:2px solid rgba(221,221,221,1);
        border-radius:50%;
        position: relative;
        &.on{
          .radio{
            opacity: 1;
          }
        }
        .radio{
          @include transition;
          opacity: 0;
          position: absolute;
          width:12px;
          height:12px;
          background-fcolor: $main_blue;
          border-radius:50%;
          top:50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .text{
          font-size:16px;

          font-weight:400;
          color:rgba(51,51,51,1);
        }
      }
    }
  }
}
</style>
