<template>
  <div
    class="components_dialog_operating_space_page"
  >
    <div class="switch_button cp"
      @click.stop="changeFunSwitch();"
      v-show="dialog_fun_status == 'template'"
      :class="{active: fun_switch}"
    ></div>
    <control-strip
      :dialog="dialog"
      @handleChange="changeDialogFunStatus"
    ></control-strip>
     <!-- 内容区 -->
    <div class="content_group"
      :class="{active: fun_switch && dialog_fun_status == 'template'}"
    >
      <!-- 模板类型 -->
      <task
        v-show="dialog_fun_status == 'template'"
        :switch_task="fun_switch"
        ref="task"
        @handleChangeSwitch="changeSwitch"
      ></task>
      <!-- 聊天 -->
      <fun-chat 
        v-show="dialog_fun_status == 'text'"
      ></fun-chat>
    </div>
  </div>
</template>
<script>
import FunChat from '@/components/dialog/Fun/Chat.vue';
import ControlStrip from './ControlStrip.vue';
import Task from './Task/Container.vue';
export default {
  components: {
    ControlStrip,
    FunChat,
    Task,
  },
  props: {
    dialog: {
      type: Object,
      default: undefined
    }
  },
  data(){
    return {
      fun_switch: false,
    }
  },
  watch:{
    $route: function(val){
      this.clear();
      this.reload();
    }
  },
  computed:{
    dialog_fun_status(){
      return this.$store.state.dialog_fun_status;
    },
  },
  methods: {
    /**
     * 改变操作区类型
     */
    changeDialogFunStatus(type){
      this.$store.commit('set_dialog_fun_status', type);
    },
    /**
     * 展开/收起
     */
    changeSwitch(type){
      this.fun_switch = type;
    },
    clear(){
      this.changeDialogFunStatus('template');
      this.changeSwitch(false);
    },
    reload(){
      this.$refs.task.init();
    },
    /**
     * @description: 打开收起 [关闭时切换为当前相关]
     * @param {*}
     * @return {*}
     */
    async changeFunSwitch(){
      this.fun_switch = !this.fun_switch;
      // if(!this.fun_switch){
      //   this.changeTemplateType(0);
      //   //关闭监听
      //   this.$refs.my_task_list_wrapper.removeEventListener('scroll', this.scrollEventListenerHandler);
      // }else{
      //   // 如果打开 判断是否需要追加一次
      //   // // console.log('如果第一次打开 判断是否需要追加一次');
      //   let {my_task_list} = this;
      //   if(my_task_list && my_task_list.length == 20){
      //     await this.loadmoreMyTaskList();
      //   }else{
      //     this.nomore = false;
      //   }
      // }
    },
  }
}
</script>
<style lang="scss" scoped>
.components_dialog_operating_space_page{
  @include transition;
  width: 100%;
  background-color: #fff;
  position: relative;
  .switch_button{
    @include background;
    width: 42px;
    height: 16px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -16px;
    background-image: url(~@/assets/images/operating_space/open.png);
    &.active{
      background-image: url(~@/assets/images/operating_space/close.png);
    }
  }
  .content_group{
    // min-height: 220px;
    height: 220px;
    &.active{
      height: 520px;
    }
  }
}
</style>
