<template>
  <div
    class="components_dialog_operating_space_task_list_page bbox d-flex align-center flex-wrap"
    ref="wrapper"
    :class="{active:switch_task}"
  >
    <div class="add_new_wrapper"
      v-if="0"
    >
      <div class="add_new cp bbox d-flex flex-column align-center justify-center"
        @click="createNew();"
      >
        <img src="@/assets/images/dialog/fun/task/new_button.png" alt class="img" />
        <div class="text">新建任务</div>
      </div>
    </div>
   
    <template
      v-for="(item, index) in list"
    >
      <thumb-task
        ref="thumbTask"
        class="thumb_task_wrapper cp"
        :key="index"
        :item="item"
        :menu="true"
        :canSetLabel="false"
        :canShare="true"
        :canCollect="false"
        :canSetStatus="true"
        :can-call="true"
        @handleClick="openTask"
        @handleShare="shareTask"
        @handleCall="callTask"
        @handleShowMenu="handleShowMenu"
      ></thumb-task>
    </template>
    <div class="item_group d-flex align-center justify-center"
      v-show="loading"
    >
      <loading></loading>
    </div>
    <div class="item_group d-flex align-center justify-center"
      v-show="!list || list.length == 0 && !loading"
    >
      <div class="no_data">暂无数据</div>
    </div>
  </div>
</template>

<script>
import tools from '@/api/tools'
import store from '@/store'
import Loading from '@/components/basic/loadingIcon';
import ThumbTask from '@/components/Common/ThumbTask.vue';
export default {
  props: {
    switch_task: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: function(){
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false,
    },
    nomore: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ThumbTask,
    Loading,
  },
  data(){
    return{
      scroll_top: -1,
      currentClickTask: undefined // 当前点击的任务
    }
  },
  watch: {
    scroll_top: async function(val){
      let {clientHeight, scrollHeight} = this.$refs.wrapper;
      if(this.nomore){
        return ;
      }
      if(this.loading){
        return;
      }
      if(val == -1){
        return;
      }
      if((scrollHeight - val - clientHeight) < 50){
        // 没有正在加载
        this.$emit('handleLoadmore');
      }
    }
  },
  methods: {
    handleShowMenu(params) {
      const { task } = params
      if (this.list && this.list.length && this.$refs.thumbTask && this.$refs.thumbTask.length) {
        const taskIndex = this.list.findIndex((item) => {
          return Number(item.id) === Number(task.id)
        })
        if (taskIndex !== -1 && this.$refs.thumbTask[taskIndex]) {
          this.$refs.thumbTask.forEach((item, index) => {
            if (item.switch_more_btn && index !== taskIndex) {
              item.switch_more_btn = false
            }
          })
        }
      }
    },
    callTask(item) {
      this.$api.moduleTask.callTask({
        taskId: item.id,
        config: {
          select_target: true,
          jump: true,
          use: true,
          open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
          open_with_call_page: false,
        }
      })
    },
    shareTask(item) {
      this.$api.share.doStartShare({
        data: Object.assign(
          {},
          item,
        ),
        type: 1
      })
    },
    clear(){
      this.scroll_top = -1;
    },
    scrollHandler(){
      this.scroll_top = this.$refs.wrapper.scrollTop;
    },
    openTask(item){
      this.$set(this, 'currentClickTask', item)
      const { $route } = this
      const { params } = $route
      const { id } = params
      const currentChatId = tools.atob(id)
      this.$api.moduleTask.openTask(
        Object.assign(
          {},
          {
            taskId:
            item.id
          },
          {
            config: {
              edit:true
            },
            currentChatId,
            callback: this.handlerMigrate
          }
        )
      )
    },
    /**
     * 任务被迁移的处理
     */
    handlerMigrate (params) {
      const { currentClickTask } = this
      const { id:taskId } = currentClickTask
      const { message_list, my_task_list } = store.state
      // 提示：已迁移
      this.$openConfirm({
        title: '该任务被迁移至其他工作组，已自动清除',
        desc: '是否跳转至该工作组查看？',
        onSure: () => {
          try {
            const { task } = params.contents
            const { chatId } = task
            this.$router.push({
              name: 'fun_task_dialog_group',
              params: {
                id: tools.btoa(chatId)
              }
            })

          } catch (e) {
            console.log(e)
          }
        }
      })
      // 清除当前message_list的相关任务
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const taskLogIndex = message_list.findIndex((unit) => {
            return Number(unit.taskId) === Number(taskId)
          })
          if (taskLogIndex !== -1) {
            message_list.splice(taskLogIndex, 1)
          } else {
            break
          }
        }

      } catch (e) {
        console.log(e)
      }
      // 清除任务区的相关任务
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const myTaskListIndex = my_task_list.findIndex((unit) => {
            return Number(unit.id) === Number(taskId)
          })
          if (myTaskListIndex !== -1) {
            my_task_list.splice(myTaskListIndex, 1)
          } else {
            break
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
  mounted(){
    this.$refs.wrapper.addEventListener('scroll', this.scrollHandler, false);
  }
}
</script>

<style lang="scss" scoped>
.components_dialog_operating_space_task_list_page{
  width: 100%;
  padding: 0 5px 10px;
  margin-top: 10px;
  height: 150px;
  overflow: hidden;
  position: relative;
  &.active{
    @include scrollbar;
    height: 450px;
    overflow-y: auto;

  }
  .add_new_wrapper{
    position: sticky;
    top: 0;
    left: -5px;
    background-color: #fff;
    z-index: 3;
    margin: {
      right: 10px;
      bottom: 10px;
      left: -5px;
    }
    width: 160px;
    padding-left: 5px;
  }
  .add_new {
   
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    border: 1px solid #E8EEFF;
    z-index: 3;
  
    width: 160px;
    height: 140px;
   
    .text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $main_blue;
      padding-top: 10px;
    }
    .btn_group{
      @include bbox;
      @include flex;
      @include u_sel_none;
      border-radius: 10px;
      justify-content: center;
      width: 100%;
      height: 140px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid #E8EEFF;
      box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);
    }
  }
  .thumb_task_wrapper{
    position: relative;
    z-index: 2;
    margin: {
      right: 10px;
      bottom: 10px;
    }
  }
  .item_group{
    margin: {
      right: 10px;
      bottom: 10px;
    }
    width: 160px;
    height: 140px;
  }
  .no_data{
    font-size: 14px;
    color:#999;
  }
}
</style>