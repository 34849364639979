var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_fun_task_public_notice_page bbox d-flex align-stretch",
    },
    [
      _c(
        "div",
        { staticClass: "main_group bbox d-flex flex-column" },
        [
          _c("Main", {
            ref: "main",
            staticClass: "main",
            attrs: {
              select_target: _vm.select_target,
              public_notice: _vm.public_notice,
              editable: _vm.is_lord,
              view: _vm.view,
            },
            on: { handleCall: _vm.callTask },
          }),
          _vm.fun_status
            ? _c(
                "div",
                { staticClass: "send_group bbox d-flex align-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "button send btn_dark",
                      on: { click: _vm.doSend },
                    },
                    [_vm._v("\n        发布\n      ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "button cancel btn_light",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.doClose.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n        取消\n      ")]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.is_lord
        ? _c("side-bar", {
            staticClass: "flex-shrink-0 side_bar",
            attrs: {
              type: _vm.sidebar_type,
              public_notice: _vm.public_notice,
              editable: _vm.editable,
            },
            on: {
              handleChangePannel: _vm.changePannel,
              handleAddNewWidget: _vm.addNewWidget,
              handleSwitchSidebar: _vm.switchSidebar,
              handleSavaTask: _vm.handleSavaTask,
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "close_btn cp",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doClose.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "text" }, [_vm._v("关闭公示板")]),
          _c("img", {
            staticClass: "switch close",
            attrs: {
              src: require("@/assets/images/dialog/announcement_close.png"),
              alt: "",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }