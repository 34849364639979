import Task from '@/models/task'
import apiModuleTask from '@/api/modules/task'
import store from '@/store'
import vue from '@/main'
import request from '@/api/request'

const createTask = async () => {
  // const res = await request.request(
  //   '/resource/createTeachTask',
  //   {
  //     name: 'ceshi',
  //     controls: '',
  //     price: 0,
  //     introduction: 'none',
  //     teachType: 1,
  //   }
  // )
  console.log(Task)
  const task = new Task({})
  console.log(task)
  await oldOpenTask({
    config: {
      create: true
    }
  })
}

const task = {
  createTask
}

export default task

export {
  createTask
}

async function oldOpenTask (params) {
  await apiModuleTask.resetCallConfig()
  // this.$nextTick(function(){
  //   this.$api.moduleTask.openTask({config:{create: true}});
  // })
  const { taskId, config, url, callback, currentChatId } = params
  const { call } = config
  const ctask_list = store.state.ctask_list
  console.log(ctask_list)
  // 如果不是调用 && 已经是最小化任务 则直接打开最小化的任务
  if (ctask_list && ctask_list.length) {
    const taskListIndex = ctask_list.findIndex((item) => {
      return Number(item.otask.task.id) === Number(taskId)
    })
    if (taskListIndex !== -1 && !call) {
      await apiModuleTask.recoverTask(ctask_list[taskListIndex])
      ctask_list.splice(taskListIndex, 1)
      return
    }
  }

  const { commit } = store
  apiModuleTask.clearTaskData()
  commit(
    'set_task_config',
    Object.assign(
      {},
      vue.$store.state.task_config,
      config
    )
  )
  const task_detail_res = await apiModuleTask.taskDetail({
    taskId,
    url
  })
  console.log(vue.$store.state.task_config, config)
  // vue.$hideLoading()
  if (task_detail_res.message !== 'success') {
    return
  }


  // 如果传入了currentChatId 则判断不是当前群聊的话 弹出提示
  // if (task_detail_res && task_detail_res.contents && task_detail_res.contents.task && Number(task_detail_res.contents.task.chatType) !== 0) {
  //   if ((currentChatId ?? '') !== '') {
  //     if (Number(currentChatId) !== Number(task_detail_res.contents.task.chatId)) {
  //       if (callback) {
  //         callback(task_detail_res)
  //       }
  //       return
  //     }
  //   }
  // }
  // commit('set_switch_task_box', true) //打开
  vue.$modal.show('taskEdit')
}
