var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_dialog_operating_space_page" },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dialog_fun_status == "template",
            expression: "dialog_fun_status == 'template'",
          },
        ],
        staticClass: "switch_button cp",
        class: { active: _vm.fun_switch },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.changeFunSwitch()
          },
        },
      }),
      _c("control-strip", {
        attrs: { dialog: _vm.dialog },
        on: { handleChange: _vm.changeDialogFunStatus },
      }),
      _c(
        "div",
        {
          staticClass: "content_group",
          class: {
            active: _vm.fun_switch && _vm.dialog_fun_status == "template",
          },
        },
        [
          _c("task", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dialog_fun_status == "template",
                expression: "dialog_fun_status == 'template'",
              },
            ],
            ref: "task",
            attrs: { switch_task: _vm.fun_switch },
            on: { handleChangeSwitch: _vm.changeSwitch },
          }),
          _c("fun-chat", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dialog_fun_status == "text",
                expression: "dialog_fun_status == 'text'",
              },
            ],
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }