var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog_drawer_single_wrapper" }, [
    _c("div", { staticClass: "user_list_group" }, [
      _c("div", { staticClass: "list_group" }, [
        !_vm.isSystemUser()
          ? _c(
              "div",
              {
                staticClass: "add cp",
                on: { click: _vm.openAddContactToDialog },
              },
              [
                _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: require("@/assets/images/dialog/drawer_add.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "text" }, [_vm._v("添加")]),
              ]
            )
          : _vm._e(),
        _vm.user_data &&
        _vm.user_data.userId == _vm.$api.moduleTask.getRouteParamsId()
          ? _c(
              "div",
              { staticClass: "unit" },
              [
                _c("WorkTeamDetailUnit", {
                  attrs: {
                    width: "44px",
                    imgwidth: "44px",
                    imgheight: "44px",
                    placement: "left-start",
                    userData: _vm.user_data,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _vm.chat_detail && !_vm.isSystemUser()
      ? _c("div", { staticClass: "switch_group" }, [
          _c("div", { staticClass: "switch_line" }, [
            _c("div", { staticClass: "text" }, [_vm._v("消息免打扰")]),
            _c(
              "div",
              { staticClass: "switch" },
              [
                _c("el-switch", {
                  attrs: {
                    "active-color": "#0054A6",
                    "inactive-color": "#DDDDDD",
                    "active-value": 1,
                    "inactive-value": 0,
                  },
                  on: { change: _vm.changeDoNotDistrub },
                  model: {
                    value: _vm.chat_detail.isdisturb,
                    callback: function ($$v) {
                      _vm.$set(_vm.chat_detail, "isdisturb", $$v)
                    },
                    expression: "chat_detail.isdisturb",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "switch_line" }, [
            _c("div", { staticClass: "text" }, [_vm._v("置顶")]),
            _c(
              "div",
              { staticClass: "switch" },
              [
                _c("el-switch", {
                  attrs: {
                    "active-color": "#0054A6",
                    "inactive-color": "#DDDDDD",
                    "active-value": 1,
                    "inactive-value": 0,
                  },
                  on: { change: _vm.changeIstop },
                  model: {
                    value: _vm.chat_detail.istop,
                    callback: function ($$v) {
                      _vm.$set(_vm.chat_detail, "istop", $$v)
                    },
                    expression: "chat_detail.istop",
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }