import { listConvertToModel } from '@/models/index'

class TaskDetail {
  address
  coder
  content
  controlLinkJson
  controlLinkList
  countdown
  detailsLevel
  fileJson
  fileList
  id
  islink
  islock
  linkId
  provinceCoder
  remark
  remindList
  screenList
  screens
  screensrandom
  screenstarttime
  screenstatus
  screenuserid
  screenusername
  screenuserthumbnail
  sort
  sortJson
  taskId
  timetype
  type
  unit
  updatenum
  userId
  userList
  userlock

  constructor (params) {
    const {
      address,
      coder,
      content,
      controlLinkJson,
      controlLinkList,
      countdown,
      detailsLevel,
      fileJson,
      fileList,
      id,
      islink,
      islock,
      linkId,
      provinceCoder,
      remark,
      remindList,
      screenList,
      screens,
      screensrandom,
      screenstarttime,
      screenstatus,
      screenuserid,
      screenusername,
      screenuserthumbnail,
      sort,
      sortJson,
      taskId,
      timetype,
      type,
      unit,
      updatenum,
      userId,
      userList,
      userlock,
    } = params ?? {}

    this.address = address
    this.coder = coder
    this.content = content
    this.controlLinkJson = controlLinkJson
    this.controlLinkList = listConvertToModel({
      list: controlLinkList,
      model: TaskDetail
    })
    this.countdown = countdown
    this.detailsLevel = detailsLevel
    this.fileJson = fileJson
    this.fileList = fileList
    this.id = id
    this.islink = islink
    this.islock = islock
    this.linkId = linkId
    this.provinceCoder = provinceCoder
    this.remark = remark
    this.remindList = remindList
    this.screenList = screenList
    this.screens = screens
    this.screensrandom = screensrandom
    this.screenstarttime = screenstarttime
    this.screenstatus = screenstatus
    this.screenuserid = screenuserid
    this.screenusername = screenusername
    this.screenuserthumbnail = screenuserthumbnail
    this.sort = sort
    this.sortJson = sortJson
    this.taskId = taskId
    this.timetype = timetype
    this.type = type
    this.unit = unit
    this.updatenum = updatenum
    this.userId = userId
    this.userList = userList
    this.userlock = userlock
  }
}

export default TaskDetail
