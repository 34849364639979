var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_dialog_operating_space_control_strip_page bbox d-flex align-center",
    },
    [
      _c("div", {
        staticClass: "fun template",
        class: { on: _vm.dialog_fun_status == "template" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.changeDialogFunStatus("template")
          },
        },
      }),
      _c("div", {
        staticClass: "fun text",
        class: { on: _vm.dialog_fun_status == "text" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.changeDialogFunStatus("text")
          },
        },
      }),
      0
        ? _c(
            "el-upload",
            {
              staticClass: "fun",
              attrs: {
                action: "https://jsonplaceholder.typicode.com/posts/",
                multiple: "",
                "show-file-list": false,
              },
            },
            [
              _c("div", { attrs: { slot: "trigger" }, slot: "trigger" }, [
                _c("div", {
                  staticClass: "fun file",
                  class: { on: _vm.dialog_fun_status == "file" },
                  on: {
                    click: function ($event) {
                      _vm.dialog_fun_status = "file"
                    },
                  },
                }),
              ]),
            ]
          )
        : _vm._e(),
      0
        ? _c("div", {
            staticClass: "fun file",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.touchImage.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _c("input", {
        ref: "image",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "image/*", multiple: "" },
        on: { change: _vm.fileChange },
      }),
      1
        ? _c("div", {
            staticClass: "fun record",
            class: { on: _vm.dialog_fun_status == "record" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$openCommunicationRecord()
              },
            },
          })
        : _vm._e(),
      _vm.switch_record && 0
        ? _c("record-of-communication", {
            on: { handleClose: _vm.closeRecord },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }