var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_dialog_operating_space_task_search_page d-flex align-end justify-space-between bbox",
    },
    [
      _c("div", { staticClass: "list d-flex align-start" }, [
        _c(
          "div",
          {
            ref: "search",
            staticClass: "search_group flex-shrink-0 d-flex align-center bbox",
            class: { active: _vm.switch_task },
          },
          [
            _c("div", { staticClass: "icon", on: { click: _vm.changeSwitch } }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.keyword,
                  expression: "keyword",
                },
              ],
              ref: "keyword",
              staticClass: "search bbox",
              attrs: { type: "text", placeholder: "搜索" },
              domProps: { value: _vm.keyword },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.doSearch($event)
                },
                click: _vm.changeSwitch,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.keyword = $event.target.value
                },
              },
            }),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.keyword.trim() != "",
                  expression: "keyword.trim() != ''",
                },
              ],
              staticClass: "clear cp",
              on: {
                click: function ($event) {
                  return _vm.clearKeyword()
                },
              },
            }),
          ]
        ),
        0
          ? _c(
              "div",
              {
                ref: "wrapper",
                staticClass: "tag_list d-flex align-center flex-wrap",
                class: { active: _vm.switch_task },
              },
              [
                _c(
                  "div",
                  {
                    ref: "tag",
                    staticClass: "tag cp flex-shrink-0",
                    class: { active: _vm.all_status },
                    on: {
                      click: function ($event) {
                        return _vm.touchAll()
                      },
                    },
                  },
                  [_vm._v("全部")]
                ),
                _vm._l(_vm.tag_list, function (item, index) {
                  return [
                    _c(
                      "div",
                      {
                        key: index,
                        ref: "tag",
                        refInFor: true,
                        staticClass: "tag cp flex-shrink-0",
                        class: { active: item.active },
                        on: {
                          click: function ($event) {
                            return _vm.touchTag(item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.tagName))]
                    ),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }