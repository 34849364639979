var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass:
        "components_dialog_operating_space_task_list_page bbox d-flex align-center flex-wrap",
      class: { active: _vm.switch_task },
    },
    [
      0
        ? _c("div", { staticClass: "add_new_wrapper" }, [
            _c(
              "div",
              {
                staticClass:
                  "add_new cp bbox d-flex flex-column align-center justify-center",
                on: {
                  click: function ($event) {
                    return _vm.createNew()
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "img",
                  attrs: {
                    src: require("@/assets/images/dialog/fun/task/new_button.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "text" }, [_vm._v("新建任务")]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._l(_vm.list, function (item, index) {
        return [
          _c("thumb-task", {
            key: index,
            ref: "thumbTask",
            refInFor: true,
            staticClass: "thumb_task_wrapper cp",
            attrs: {
              item: item,
              menu: true,
              canSetLabel: false,
              canShare: true,
              canCollect: false,
              canSetStatus: true,
              "can-call": true,
            },
            on: {
              handleClick: _vm.openTask,
              handleShare: _vm.shareTask,
              handleCall: _vm.callTask,
              handleShowMenu: _vm.handleShowMenu,
            },
          }),
        ]
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "item_group d-flex align-center justify-center",
        },
        [_c("loading")],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.list || (_vm.list.length == 0 && !_vm.loading),
              expression: "!list || list.length == 0 && !loading",
            },
          ],
          staticClass: "item_group d-flex align-center justify-center",
        },
        [_c("div", { staticClass: "no_data" }, [_vm._v("暂无数据")])]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }