var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "components_dialog_operating_space_task_container_page bbox",
    },
    [
      _c(
        "div",
        { staticClass: "operation_group d-flex align-center bbox" },
        [
          _c(
            "div",
            {
              staticClass:
                "create_group d-flex bbox justify-center ml-5 mr-5 un_sel cp",
              on: { click: _vm.createNew },
            },
            [
              _c("div", { staticClass: "icon" }),
              _c("div", { staticClass: "text pl-10" }, [_vm._v("新建任务")]),
            ]
          ),
          _c("search", {
            ref: "search",
            attrs: { list: _vm.tag_list, switch_task: _vm.switch_task },
            on: {
              handleChangeSwitch: _vm.changeSwitch,
              handleChange: _vm.changeParams,
            },
          }),
        ],
        1
      ),
      _c("list", {
        ref: "list",
        attrs: {
          switch_task: _vm.switch_task,
          list: _vm.task_list,
          loading: _vm.loading,
          nomore: _vm.nomore,
        },
        on: { handleLoadmore: _vm.loadMoreTaskList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }